import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpParams,
  HttpEventType,
} from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { cloneDeep as _cloneDeep } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Lightbox } from 'ngx-lightbox';
import { AppService } from '../../app.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

interface Advertisement {
  recno?: number;
  name: string;
  image: string;
  url: string;
}

@Component({
  selector: 'app-ad-setting',
  templateUrl: './ad-setting.component.html',
  styleUrls: ['./ad-setting.component.scss'],
})
export class AdSettingComponent implements OnInit {
  isAdvertisementModalVisible = false;
  uploading = false;
  uploadImageSuccess = false;
  uploadImagePercent;
  fileListImage: NzUploadFile[] = [];

  addAdvertisementData: Advertisement = {
    name: '',
    image: '',
    url: '',
  };
  selectAdvertisementData: Advertisement;

  imageFile: FormData = new FormData();

  loading = false;
  listOfAdvertisementCurrentPageData: Advertisement[] = [];
  totalCount: number;
  pageSize = 10;
  pageIndex = 1;

  constructor(
    private http: HttpClient,
    private msg: NzMessageService,
    public changeDetectorRef: ChangeDetectorRef,
    private modal: NzModalService,
    private _lightbox: Lightbox,
    public appService: AppService // tslint:disable-next-line: variable-name
  ) {}

  ngOnInit(): void {}

  onAdvertisementQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex } = params;
    this.pageSize = pageIndex;
    this.pageSize = pageSize;
    this.loadAdvertisementDataFromServer(pageIndex, pageSize);
  }

  loadAdvertisementDataFromServer(pageIndex: number, pageSize: number): void {
    this.loading = true;

    const params = new HttpParams()
      .append('pageIndex', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    this.http
      .get<any>('/api/advertisement', { params })
      .toPromise()
      .then((res) => {
        console.log(res);
        let protocol = window.location.href.split("://")[0];
        res.data.forEach(element => {
            element.image = element.image.replace("http.*?://",protocol.concat("://"));
            console.log(element.image);
        });
        console.log(res.data);
        this.loading = false;
        this.listOfAdvertisementCurrentPageData = res.data;
        this.totalCount = res.total;
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  editVideoModal(data): void {
    this.selectAdvertisementData = _cloneDeep(data);
    this.addAdvertisementData = _cloneDeep(data);
    this.imageFile = new FormData();
    this.fileListImage = [{ name: data.image, uid: '1', filename: data.image }];
    this.isAdvertisementModalVisible = true;
  }

  closeModal(): void {
    this.isAdvertisementModalVisible = false;
    this.selectAdvertisementData = null;
    this.uploadImagePercent = null;
    this.fileListImage = [];
  }

  beforeUploadImage = (file): boolean => {
    this.fileListImage = this.fileListImage.concat(file);
    if (this.fileListImage.length > 1) {
      this.fileListImage.shift();
    }
    this.imageFile.set('image', file);
    return false;
  };

  // 修改
  async putForm(): Promise<boolean> {
    if (!this.addAdvertisementData.url) {
      this.msg.warning('影片連結不可空白');
      return false;
    }

    this.uploadImageSuccess = true;
    if (this.imageFile.get('image')) {
      this.uploadImageSuccess = false;

      const reqImage = new HttpRequest(
        'POST',
        '/api/upload/image',
        this.imageFile,
        {
          reportProgress: true,
        }
      );

      this.http.request(reqImage).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadImagePercent = (
              (event.loaded / event.total) *
              100
            ).toFixed(2);
          }
          if (event.type === HttpEventType.Response) {
            this.msg.create('success', '圖片上傳成功');
            // console.log(event.body);
            this.addAdvertisementData.image = (event.body as any).url;
            this.uploadImageSuccess = true;
            this.put();
          }
        },
        (error) => {
          console.log(error.message);
          this.msg.create('waring', '圖片上傳失敗');
        }
      );
    }

    if (!this.imageFile.get('image')) {
      this.put();
    }
  }

  put(): void {
    if (this.uploadImageSuccess) {
      this.http
        .put<any>('/api/advertisement', this.addAdvertisementData)
        .toPromise()
        .then((res) => {
          this.msg.create('success', '修改成功');
          this.loadAdvertisementDataFromServer(this.pageIndex, this.pageSize);
        })
        .catch((error) => {
          console.log(error.message);
          this.msg.create('waring', '修改失敗');
        });

      this.closeModal();
    }
  }

  openImage(src, index): void {
    const album = [
      {
        src,
        caption: src,
        thumb: src,
      },
    ];
    this._lightbox.open(album, 0);
  }
}
