import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpParams,
  HttpEventType,
} from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { cloneDeep as _cloneDeep } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Lightbox } from 'ngx-lightbox';
import { AppService } from '../../app.service';
import { map } from 'rxjs/operators';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

interface VideoList {
  recno?: number;
  name: string;
  category: number;
  categoryName: string;
  keywords: Array<string>;
  image: string;
  // dealer: string;
  // dealerName?: string;
  video: string;
  wavID: number;
}

interface CategoryList {
  recno?: number;
  name: string;
  image: string;
}

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss'],
})
export class UploadVideoComponent implements OnInit {
  uploading = false;
  uploadImageSuccess = false;
  uploadVideoSuccess = false;
  uploadImagePercent;
  uploadVideoPercent;
  fileListVideo: NzUploadFile[] = [];
  fileListImage: NzUploadFile[] = [];

  isPreviewVideoModalVisible = false;
  isVideoModalVisible = false;
  addVideoData: VideoList = {
    name: '',
    category: 0,
    categoryName: '',
    keywords: [],
    image: '',
    video: '',
    wavID: null,
    // dealer: 'all',
  };
  selectVideoData: VideoList;

  isCategoryModalVisible = false;
  addCategoryData: CategoryList = {
    name: '',
    image: '',
  };
  selectCategoryData: CategoryList;
  listOfCategoryCurrentPageData: CategoryList[] = [];

  newkeyword: string;
  videoFile: FormData = new FormData();
  imageFile: FormData = new FormData();

  // selectedNewKeyWordValue = null;
  // listOfOption: Array<{ recno: number; name: string }> = [];
  inputVisible = false;
  inputValue?: string;
  // dealerOptions = [];
  listOfOption: Array<{ label: string; value: number }> = [];
  listOfCategoryOption: Array<{ name: string; recno: number }> = [];

  loading = false;
  listOfVideoCurrentPageData: VideoList[] = [];
  totalCount: number;
  totalCountForVideo: number;
  totalCountForCategory: number;
  pageSizeForVideo = 10;
  pageIndexForVideo = 1;
  pageSizeForCategory = 10;
  pageIndexForCategory = 1;

  @ViewChild('inputElement') inputElement?: ElementRef;

  constructor(
    private http: HttpClient,
    private msg: NzMessageService,
    public changeDetectorRef: ChangeDetectorRef,
    private modal: NzModalService,
    private _lightbox: Lightbox,
    public appService: AppService // tslint:disable-next-line: variable-name
  ) {}

  ngOnInit(): void {
    // setTimeout(() => {
    //   console.log('123');
    //   this.loadVideoDataFromServer(this.pageIndex, this.pageSize);
    // }, 1000);
  }

  chageTab(str): void {
    // if (str === '1') {
    //   this.loadVideoDataFromServer(this.pageIndex, this.pageSize);
    // } else if (str === '2') {
    //   this.loadCategoryDataFromServer(this.pageIndex, this.pageSize);
    // }
  }

  searchOptions(value: string): void {
    this.http
      .jsonp<{ result: Array<{ recno: number; name: string }> }>(
        `/api/jsonp?key=soundWave2&searchText=${value}`,
        // `http://soundwave.umtv.com.tw/api/jsonp?key=soundWave2&searchText=${value}`,
        'callback'
      )
      .subscribe((data) => {
        const listOfOption: Array<{ value: number; label: string }> = [];
        data.result.forEach((item) => {
          listOfOption.push({
            value: item.recno,
            label: item.name,
          });
        });
        this.listOfOption = listOfOption;
      });
  }

  onVideoQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex } = params;
    this.pageSizeForVideo = pageIndex;
    this.pageSizeForVideo = pageSize;
    this.loadVideoDataFromServer(pageIndex, pageSize);
  }

  onCategoryQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex } = params;
    this.pageIndexForCategory = pageIndex;
    this.pageSizeForCategory = pageSize;
    this.loadCategoryDataFromServer(pageIndex, pageSize);
  }

  loadVideoDataFromServer(pageIndex: number, pageSize: number): void {
    this.loading = true;

    const params = new HttpParams()
      .append('pageIndex', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    this.http
      .get<any>('/api/video', { params })
      .toPromise()
      .then((res) => {
        // console.log(res);
        this.loading = false;
        this.listOfVideoCurrentPageData = res.data;
        this.totalCountForVideo = res.total;
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  loadCategoryDataFromServer(pageIndex: number, pageSize: number): void {
    this.loading = true;

    const params = new HttpParams()
      .append('pageIndex', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    this.http
      .get<any>('/api/category', { params })
      .toPromise()
      .then((res) => {
        // console.log(res);
        this.loading = false;
        this.listOfCategoryOption = res.data;
        this.listOfCategoryCurrentPageData = res.data;
        this.totalCountForCategory = res.total;
      })
      .catch((err) => {
        console.log(err.message);
        this.loading = false;
      });
  }

  // nzFilterOption = () => true;
  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  showInput(): void {
    this.inputVisible = true;
    // console.log(this.inputElement);
    setTimeout(() => {
      // console.log(this.inputElement);
      this.inputElement?.nativeElement.focus();
    }, 10);
  }
  handleInputConfirm(event): void {
    if (
      (event.type === 'keypress' && event.keyCode === 13) ||
      event.type === 'blur'
    ) {
      if (
        this.inputValue &&
        this.addVideoData.keywords.indexOf(this.inputValue) === -1
      ) {
        this.addVideoData.keywords = [
          ...this.addVideoData.keywords,
          this.inputValue,
        ];
      }
      this.inputValue = '';
      this.inputVisible = false;
    }
  }
  handleClose(removedTag): void {
    // console.log(removedTag);
    this.addVideoData.keywords = this.addVideoData.keywords.filter(
      (tag) => tag !== removedTag
    );
  }
  editVideoModal(data): void {
    this.selectVideoData = _cloneDeep(data);
    this.addVideoData = _cloneDeep(data);
    this.videoFile = new FormData();
    this.imageFile = new FormData();
    this.fileListVideo = [{ name: data.video, uid: '1', filename: data.video }];
    this.fileListImage = [{ name: data.image, uid: '1', filename: data.image }];
    this.isVideoModalVisible = true;

    this.http
      .jsonp<{ result: Array<{ recno: number; name: string }> }>(
        `/api/jsonp?key=soundWave2&wavID=${data.wavID}`,
        // `http://soundwave.umtv.com.tw/api/jsonp?key=soundWave2&wavID=${data.wavID}`,
        'callback'
      )
      .subscribe((data2) => {
        const listOfOption: Array<{ value: number; label: string }> = [];
        data2.result.forEach((item) => {
          listOfOption.push({
            value: item.recno,
            label: item.name,
          });
        });
        this.listOfOption = listOfOption;
      });
    // console.log(this.addVideoData.keywords);
  }

  editCategoryModal(data): void {
    this.selectCategoryData = _cloneDeep(data);
    this.addCategoryData = _cloneDeep(data);
    this.isCategoryModalVisible = true;
    this.fileListImage = [{ name: data.image, uid: '1', filename: data.image }];
  }

  showVideoModal(data): void {
    this.selectVideoData = null;
    this.selectVideoData = _cloneDeep(data);
    this.isPreviewVideoModalVisible = true;
  }
  showAddModal(): void {
    this.inputValue = '';
    this.addVideoData = {
      name: '',
      category: 0,
      categoryName: '',
      keywords: [],
      image: '',
      video: '',
      wavID: null,
      // dealer: 'all',
    };
    this.addVideoData.keywords = [];
    this.fileListVideo = [];
    this.fileListImage = [];
    this.videoFile = new FormData();
    this.imageFile = new FormData();
    this.isVideoModalVisible = true;
    this.selectVideoData = null;
    this.uploadImagePercent = null;
    this.uploadVideoPercent = null;
    this.uploadImageSuccess = false;
    this.uploadVideoSuccess = false;
  }

  showAddCategoryModal(): void {
    if (this.totalCountForCategory >= 12) {
      this.appService.showMessage('warning', '最多只能12個類別');
      return;
    }

    this.addCategoryData = {
      name: '',
      image: '',
    };
    this.fileListImage = [];
    this.imageFile = new FormData();
    this.isCategoryModalVisible = true;
    this.selectCategoryData = null;
    this.uploadImagePercent = null;
  }

  closePreviewVideoModal(): void {
    this.isPreviewVideoModalVisible = false;
    this.selectVideoData = null;
    this.uploadImagePercent = null;
    this.uploadVideoPercent = null;
    this.uploadImageSuccess = false;
    this.uploadVideoSuccess = false;
  }
  closeVideoModal(): void {
    this.isVideoModalVisible = false;
    this.selectVideoData = null;
    this.uploadImagePercent = null;
    this.uploadVideoPercent = null;
    this.uploadImageSuccess = false;
    this.uploadVideoSuccess = false;
  }
  closeCategoryModal(): void {
    this.isCategoryModalVisible = false;
    this.selectCategoryData = null;
    this.uploadImagePercent = null;
    this.fileListImage = [];
  }

  beforeUploadVideo = (file): boolean => {
    if (file.size > 200 * 1024 * 1000) {
      this.msg.warning('影片太大，不得超過200MB');
      return false;
    }

    this.fileListVideo = this.fileListVideo.concat(file);
    if (this.fileListVideo.length > 1) {
      this.fileListVideo.shift();
    }
    this.videoFile.set('video', file);
    return false;
  };
  beforeUploadImage = (file): boolean => {
    this.fileListImage = this.fileListImage.concat(file);
    if (this.fileListImage.length > 1) {
      this.fileListImage.shift();
    }
    this.imageFile.set('image', file);
    return false;
  };

  // 送出
  postVideoForm(): void {
    // this.postVideo();
    // return;
    if (!this.imageFile.has('image')) {
      this.msg.warning('請選擇預覽圖');
      return;
    }
    if (!this.videoFile.has('video')) {
      this.msg.warning('請選擇影片');
      return;
    }
    if (!this.addVideoData.name) {
      this.msg.warning('影片名稱不可空白');
      return;
    }
    this.msg.create('warning', '上傳中...');

    const reqImage = new HttpRequest(
      'POST',
      '/api/upload/image',
      // 'http://soundwave.umtv.com.tw/api/upload/image',
      this.imageFile,
      {
        reportProgress: true,
      }
    );

    this.http.request(reqImage).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          // console.log(event);

          this.uploadImagePercent = (
            (event.loaded / event.total) *
            100
          ).toFixed(2);
        }
        if (event.type === HttpEventType.Response) {
          this.msg.create('success', '圖片上傳成功');
          // console.log(event.body);
          this.addVideoData.image = (event.body as any).url;
          this.uploadImageSuccess = true;
          this.postVideo();
        }
      },
      (error) => {
        console.log(error.message);
        this.msg.create('waring', '圖片上傳失敗');
      }
    );

    const reqVideo = new HttpRequest(
      'POST',
      '/api/upload/video',
      this.videoFile,
      {
        reportProgress: true,
      }
    );

    this.http.request(reqVideo).subscribe(
      // event.type 有 Sent = 0 UploadProgress = 1 DownloadProgress = 3 Response = 4
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadVideoPercent = (
            (event.loaded / event.total) *
            100
          ).toFixed(2);
        }
        if (event.type === HttpEventType.Response) {
          this.msg.create('success', '上傳影片成功');
          this.addVideoData.video = (event.body as any).url; // (event.body as any) 解決 typescript 沒有先設定 property的問題
          this.uploadVideoSuccess = true;
          this.postVideo();
        }
      },
      (error) => {
        console.log(error.message);
        this.msg.create('waring', '上傳影片失敗');
      }
    );
  }

  postVideo(): void {
    if (this.uploadImageSuccess && this.uploadVideoSuccess) {
      this.http
        .post<any>('/api/video', this.addVideoData)
        .toPromise()
        .then((res) => {
          this.msg.create('success', '新增成功');
          this.loadVideoDataFromServer(
            this.pageIndexForVideo,
            this.pageSizeForVideo
          );
        })
        .catch((error) => {
          console.log(error.message);
          this.msg.create('waring', '新增失敗');
        });

      this.closeVideoModal();
    }
  }
  // 修改
  async putVideoForm(): Promise<boolean> {
    if (!this.addVideoData.name) {
      this.msg.warning('影片名稱不可空白');
      return false;
    }

    this.uploadImageSuccess = true;
    this.uploadVideoSuccess = true;
    if (this.imageFile.get('image')) {
      this.uploadImageSuccess = false;

      const reqImage = new HttpRequest(
        'POST',
        '/api/upload/image',
        this.imageFile,
        {
          reportProgress: true,
        }
      );

      this.http.request(reqImage).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadImagePercent = (
              (event.loaded / event.total) *
              100
            ).toFixed(2);
          }
          if (event.type === HttpEventType.Response) {
            this.msg.create('success', '圖片上傳成功');
            // console.log(event.body);
            this.addVideoData.image = (event.body as any).url;
            this.uploadImageSuccess = true;
            this.putVideo();
          }
        },
        (error) => {
          console.log(error.message);
          this.msg.create('waring', '圖片上傳失敗');
        }
      );
    }
    if (this.videoFile.get('video')) {
      // let uploadVideoSuccess = false;
      // this.closeAddModal();
      // await this.http
      //   .post<any>('/api/upload/video', this.videoFile)
      //   .toPromise()
      //   .then((res) => {
      //     const video = res.url;
      //     this.msg.create('success', '影片上傳成功');
      //     this.addVideoData.video = video;
      //     uploadVideoSuccess = true;
      //   })
      //   .catch((error) => {
      //     console.log(error.message);
      //     this.msg.create('waring', '影片上傳失敗');
      //   });
      // if (!uploadVideoSuccess) {
      //   this.appService.onLoading(false);
      //   return false;
      // }
      this.uploadVideoSuccess = false;

      const reqVideo = new HttpRequest(
        'POST',
        '/api/upload/video',
        this.videoFile,
        {
          reportProgress: true,
        }
      );

      this.http.request(reqVideo).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadVideoPercent = (
              (event.loaded / event.total) *
              100
            ).toFixed(2);
          }
          if (event.type === HttpEventType.Response) {
            this.msg.create('success', '上傳影片成功');
            this.addVideoData.video = (event.body as any).url;
            this.uploadVideoSuccess = true;
            this.putVideo();
          }
        },
        (error) => {
          console.log(error.message);
          this.msg.create('waring', '上傳影片失敗');
        }
      );
    }

    if (!this.videoFile.get('video') && !this.imageFile.get('image')) {
      this.putVideo();
    }
  }

  putVideo(): void {
    if (this.uploadImageSuccess && this.uploadVideoSuccess) {
      this.http
        .put<any>('/api/video', this.addVideoData)
        .toPromise()
        .then((res) => {
          this.msg.create('success', '修改成功');
          this.loadVideoDataFromServer(
            this.pageIndexForVideo,
            this.pageSizeForVideo
          );
        })
        .catch((error) => {
          console.log(error.message);
          this.msg.create('waring', '修改失敗');
        });

      this.closeVideoModal();
    }
  }

  // 刪除
  deleteVideoModal(data): void {
    this.modal.confirm({
      nzTitle: '確定要刪除嗎?',
      nzContent: data.name,
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.http
          .delete<any>('/api/video', { params: { recno: data.recno } })
          .toPromise()
          .then((res) => {
            this.msg.create('success', '刪除成功');
            this.loadVideoDataFromServer(
              this.pageIndexForVideo,
              this.pageSizeForVideo
            );
          })
          .catch((err) => {
            console.log(err.message);
            this.msg.create('warning', '刪除失敗');
          });
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel'),
    });
  }
  deleteKeywords(index): void {
    // console.log(index);
    this.addVideoData.keywords.splice(index, 1);
  }

  // 送出
  postCategoryForm(): void {
    if (!this.addCategoryData.name) {
      this.msg.warning('名稱不可空白');
      return;
    }
    if (this.addCategoryData.name.length > 4) {
      this.msg.warning('名稱不能超過四個字');
      return;
    }
    if (this.imageFile.has('image')) {
      const reqImage = new HttpRequest(
        'POST',
        '/api/upload/image',
        // 'http://soundwave.umtv.com.tw/api/upload/image',
        this.imageFile,
        {
          reportProgress: true,
        }
      );

      this.http.request(reqImage).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadImagePercent = (
              (event.loaded / event.total) *
              100
            ).toFixed(2);
          }
          if (event.type === HttpEventType.Response) {
            this.msg.create('success', '圖片上傳成功');
            this.addCategoryData.image = (event.body as any).url;
            this.postCategory();
          }
        },
        (error) => {
          console.log(error.message);
          this.msg.create('waring', '圖片上傳失敗');
        }
      );
    } else {
      this.postCategory();
    }
  }

  postCategory(): void {
    this.http
      .post<any>('/api/category', this.addCategoryData)
      .toPromise()
      .then((res) => {
        this.msg.create('success', '新增成功');
        this.loadCategoryDataFromServer(
          this.pageIndexForCategory,
          this.pageSizeForCategory
        );
      })
      .catch((error) => {
        console.log(error.message);
        this.msg.create('waring', '新增失敗');
      });

    this.closeCategoryModal();
  }

  // 送出
  putCategoryForm(): void {
    if (!this.addCategoryData.name) {
      this.msg.warning('名稱不可空白');
      return;
    }
    if (this.addCategoryData.name.length > 4) {
      this.msg.warning('名稱不能超過四個字');
      return;
    }
    if (this.imageFile.has('image')) {
      const reqImage = new HttpRequest(
        'POST',
        '/api/upload/image',
        // 'http://soundwave.umtv.com.tw/api/upload/image',
        this.imageFile,
        {
          reportProgress: true,
        }
      );

      this.http.request(reqImage).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            // console.log(event);

            this.uploadImagePercent = (
              (event.loaded / event.total) *
              100
            ).toFixed(2);
          }
          if (event.type === HttpEventType.Response) {
            this.msg.create('success', '圖片上傳成功');
            this.addCategoryData.image = (event.body as any).url;
            this.putCategory();
          }
        },
        (error) => {
          console.log(error.message);
          this.msg.create('waring', '圖片上傳失敗');
        }
      );
    } else {
      this.putCategory();
    }
  }

  putCategory(): void {
    this.http
      .put<any>('/api/category', this.addCategoryData)
      .toPromise()
      .then((res) => {
        this.msg.create('success', '修改成功');
        this.loadCategoryDataFromServer(
          this.pageIndexForCategory,
          this.pageSizeForCategory
        );
      })
      .catch((error) => {
        console.log(error.message);
        this.msg.create('waring', '修改失敗');
      });

    this.closeCategoryModal();
  }

  openImage(src, index): void {
    const album = [
      {
        src,
        caption: src,
        thumb: src,
      },
    ];
    this._lightbox.open(album, 0);
  }
}
