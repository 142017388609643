import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppService } from '../../app.service';

interface QrcodeData {
  recno?: number;
  content: string;
}

interface Qrcode {
  multipleDealer;
  setQRText;
  setQRPos;
}

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QRcodeComponent implements OnInit {
  qrcode: Qrcode = {
    multipleDealer: ['all'],
    setQRText: '',
    setQRPos: '1',
  };
  listOfOption: Array<{ label: string; value: number }> = [];
  addQrcodeData: QrcodeData = { content: '' };
  isQrcodeModalVisible = false;
  dealerOptions = [];

  constructor(
    private http: HttpClient,
    private msg: NzMessageService,
    private modal: NzModalService,
    public appService: AppService
  ) {}

  ngOnInit(): void {
    this.qrcode.multipleDealer = this.appService.multipleDealer;
  }

  searchOptions(value: string): void {
    this.http
      .jsonp<{ result: Array<{ recno: number; content: string }> }>(
        `/api/jsonp?key=qrcode&searchText=${value}`,
        // `http://soundwave.umtv.com.tw/api/jsonp?key=qrcode&searchText=${value}`,
        'callback'
      )
      .subscribe((data) => {
        const listOfOption: Array<{ value: number; label: string }> = [];
        data.result.forEach((item) => {
          listOfOption.push({
            value: item.recno,
            label: item.content,
          });
        });
        this.listOfOption = listOfOption;
      });
  }

  post(): void {
    if (!this.qrcode.setQRText) {
      this.appService.showMessage('warning', '必填欄位不能空白');
      return;
    }

    this.appService.onLoading(true);

    this.http
      .get<any>('/api/sendOP', {
        params: { ...this.qrcode },
      })
      .toPromise()
      .then((res) => {})
      .catch((err) => {});

    const historyLog = {
      type: 'qrcode',
      multipleDealer: this.qrcode.multipleDealer,
      content: this.qrcode.setQRText,
    };
    this.http
      .post<any>('/api/historyLog', historyLog)
      .toPromise()
      .then((res) => {})
      .catch((err) => {});

    setTimeout(() => {
      this.appService.showMessage('warning', '已送出指令');
      this.appService.onLoading(false);
    }, 1000);
  }

  onChangeDealer(val): void {
    const lastValue = val[val.length - 1];
    if (lastValue === 'all') {
      this.qrcode.multipleDealer = ['all'];
    } else {
      const index = val.indexOf('all');
      if (index !== -1) {
        val.splice(index, 1);
      }
      this.qrcode.multipleDealer = [...val];
    }

    this.appService.setMultipleDealer(this.qrcode.multipleDealer);
  }
  showAddModal(): void {
    this.addQrcodeData = {
      content: '',
    };
    this.isQrcodeModalVisible = true;
  }
  closeAddModal(): void {
    this.isQrcodeModalVisible = false;
  }
  async submitForm(): Promise<boolean> {
    if (!this.addQrcodeData.content) {
      this.msg.warning('內容不可空白');
      return false;
    }

    await this.http
      .post<any>('/api/qrcode', this.addQrcodeData)
      .toPromise()
      .then((res) => {
        this.msg.create('success', '新增成功');
        this.closeAddModal();

        // 重新到伺服器抓一次 options
        this.searchOptions('');
      })
      .catch((error) => {
        console.log(error.message);
        this.msg.create('waring', '新增失敗');
      });

    // console.log(this.addQrcodeData);
  }
}
