import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../app.service';

interface SoundWave {
  multipleDealer;
  sendWAV;
}

@Component({
  selector: 'app-sound-wave',
  templateUrl: './sound-wave.component.html',
  styleUrls: ['./sound-wave.component.scss'],
})
export class SoundWaveComponent implements OnInit {
  soundWave: SoundWave = {
    multipleDealer: ['all'],
    sendWAV: '',
  };
  soundWaveList = [];
  dealerOptions = [];
  isSpinning = false;

  constructor(private http: HttpClient, public appService: AppService) {}

  ngOnInit(): void {
    this.soundWave.multipleDealer = this.appService.multipleDealer;

    this.http
      .get<any>('/api/soundWave')
      .toPromise()
      .then((res) => {
        // console.log(res);
        this.soundWaveList = res;
      })
      .catch((err) => {});
  }

  onChangeDealer(val): void {
    const lastValue = val[val.length - 1];
    if (lastValue === 'all') {
      this.soundWave.multipleDealer = ['all'];
    } else {
      const index = val.indexOf('all');
      if (index !== -1) {
        val.splice(index, 1);
      }
      this.soundWave.multipleDealer = [...val];
    }

    this.appService.setMultipleDealer(this.soundWave.multipleDealer);
  }
  post(): void {
    this.appService.onLoading(true);

    this.http
      .get<any>('/api/sendOP', {
        params: { ...this.soundWave },
      })
      .toPromise()
      .then((res) => {})
      .catch((err) => {});

    setTimeout(() => {
      this.appService.showMessage('warning', '已送出指令');
      this.appService.onLoading(false);
    }, 1000);
  }
}
