<div nz-row [nzGutter]="[0, 24]" nzJustify="center" class="text-center">
  <!-- <nz-spin nzSimple [nzSize]="'large'"></nz-spin> -->

  <div nz-col nzSpan="24">
    <h4 nz-typography>客戶主機顯示</h4>
  </div>

  <div nz-col nzSpan="24" style="height: calc(100vh - 200px);">
    <iframe
      src="https://www.mycasa.care/cgi-bin/getNATList.cgi?acc=umtv"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</div>
