<nz-page-header nzBackIcon [nzGhost]="false">
  <nz-page-header-title>聲波</nz-page-header-title>
  <nz-page-header-subtitle>-上傳/修改 聲波內容</nz-page-header-subtitle>
  <nz-page-header-extra>
    <button
      (click)="showAddModal()"
      nz-button
      nzType="primary"
      nzShape="circle"
    >
      <i nz-icon nzType="plus" nzTheme="outline"></i>
    </button>
  </nz-page-header-extra>
</nz-page-header>

<nz-table #nzTable [nzData]="listOfData">
  <thead>
    <tr>
      <!-- <th>經銷商</th> -->
      <th>名稱</th>
      <th>對應網址</th>
      <th>操作</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of nzTable.data">
      <!-- <td>{{ data.user }}</td> -->
      <td>{{ data.name }}</td>
      <td>
        <a
          [href]="data.url"
          target="_blank"
          style="
            width: 210px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
          >{{ data.url }}</a
        >
      </td>
      <td>
        <a (click)="showAudioModal(data)">預覽</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a (click)="editAudioModal(data)">編輯</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a (click)="deleteAudioModal(data)">刪除</a>
      </td>
    </tr>
  </tbody>
</nz-table>

<!-- 瀏覽語音檔 -->
<nz-modal
  *ngIf="selectAudioData"
  [nzFooter]="null"
  [nzVisible]="isAudioModalVisible"
  nzTitle="瀏覽聲波"
  (nzOnCancel)="closeAudioModal()"
>
  <div nz-row nzJustify="center" class="text-center">
    <audio controls>
      <source [src]="selectAudioData.audio" />
      Your browser does not support the audio element.
    </audio>
  </div>
</nz-modal>

<!-- 新增聲波 -->
<nz-modal
  [nzFooter]="null"
  [nzVisible]="isAddModalVisible"
  [nzTitle]="selectAudioData ? '修改聲波' : '新增聲波'"
  (nzOnCancel)="closeAddModal()"
>
  <form nz-form>
    <!-- <nz-form-item>
      <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="user"
        >經銷商</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-select [(ngModel)]="addAudioData.user" name="user">
          <nz-option nzValue="all" nzLabel="all"></nz-option>
          <nz-option nzValue="umtv" nzLabel="umtv"></nz-option>
          <nz-option nzValue="umtv2" nzLabel="umtv2"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item> -->

    <nz-form-item>
      <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="name"
        >名稱</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <input nz-input [(ngModel)]="addAudioData.name" trim name="name" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="url"
        >對應網址</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <input nz-input [(ngModel)]="addAudioData.url" trim name="url" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="audio"
        >聲波檔案</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-upload
          [nzAccept]="['audio/mp3', 'audio/x-wav']"
          [(nzFileList)]="fileListAudio"
          [nzBeforeUpload]="beforeUploadAudio"
        >
          <button nz-button><i nz-icon nzType="upload"></i>Select File</button>
        </nz-upload>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control
        [nzXs]="{ span: 24, offset: 0 }"
        [nzSm]="{ span: 16, offset: 6 }"
      >
        <button
          *ngIf="selectAudioData"
          nz-button
          (click)="putForm()"
          nzType="primary"
        >
          修改
        </button>
        <button
          *ngIf="!selectAudioData"
          nz-button
          (click)="submitForm()"
          nzType="primary"
        >
          新增
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>
