import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
// import { NoopAnimationsModule } from '@angular/platform-browser/animations'; //不想要有動畫的話

import { SharedMaterialModuleModule } from './shared-material-module/shared-material-module.module';

import { HomeModule } from './home/home.module';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_TW } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';

import { LoginComponent } from './components/login/login.component';
import { UserAddComponent } from './components/user-add/user-add.component';

registerLocaleData(zh);

@NgModule({
  declarations: [AppComponent, LoginComponent, UserAddComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedMaterialModuleModule,
    BrowserAnimationsModule,
    NzNoAnimationModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    HomeModule,
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: zh_TW }],
  bootstrap: [AppComponent],
})
export class AppModule {}
