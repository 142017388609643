<div nz-row [nzGutter]="[0, 24]" nzJustify="center" class="text-center">
  <div nz-col nzSpan="24">
    <h4 nz-typography>
      經由本設定，可以變更用戶首頁視頻（注意：視頻不支援Youtube網址）
    </h4>
  </div>

  <div nz-col nzSpan="24">
    <form nz-form>
      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="user"
          >經銷商</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-select
            [(ngModel)]="video.multipleDealer"
            (ngModelChange)="onChangeDealer($event)"
            nzMode="multiple"
            nzPlaceHolder="選擇經銷商"
            name="dealer"
          >
            <nz-option nzValue="all" nzLabel="all"></nz-option>
            <nz-option
              *ngFor="let option of appService.$getDealerList()"
              [nzLabel]="option.name"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="setVideo"
          >視頻網址</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-select
            name="setVideo"
            nzShowSearch
            nzServerSearch
            nzPlaceHolder="搜尋內容"
            [nzShowArrow]="false"
            (nzOnSearch)="searchOptions($event)"
            [(ngModel)]="video.setVideo"
          >
            <nz-option
              *ngFor="let item of listOfOption"
              [nzValue]="item.value"
              [nzLabel]="item.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="setVideoPos"
          >視頻位置</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-radio-group [(ngModel)]="video.setVideoPos" name="setVideoPos">
            <label nz-radio nzValue="1">左上方</label>
            <label nz-radio nzValue="2">右上方</label>
            <label nz-radio nzValue="3">左下方</label>
            <label nz-radio nzValue="4">右下方</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="setVideoSize"
          >視頻尺寸</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-radio-group [(ngModel)]="video.setVideoSize" name="setVideoSize">
            <label nz-radio nzValue="1280x720">1280*720</label>
            <label nz-radio nzValue="720*480">720*480</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </form>

    <div nz-col nzSpan="24">
      <button nz-button nzType="primary" (click)="post()">確定</button>
    </div>
  </div>
</div>
