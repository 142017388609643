import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { cloneDeep as _cloneDeep } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';

interface AudioList {
  recno?: number;
  name: string;
  audio: string;
  // user: string;
  url: string;
}

@Component({
  selector: 'app-upload-sound-wave',
  templateUrl: './upload-sound-wave.component.html',
  styleUrls: ['./upload-sound-wave.component.scss'],
})
export class UploadSoundWaveComponent implements OnInit {
  uploading = false;
  fileListAudio: NzUploadFile[] = [];
  searchValue = '';
  visible = false;
  isAudioModalVisible = false;
  isAddModalVisible = false;
  listOfData: AudioList[] = [];
  addAudioData: AudioList = { name: '', audio: '', url: '' };
  newkeyword: string;
  audioFile: FormData = new FormData();
  selectAudioData: AudioList;

  constructor(
    private http: HttpClient,
    private msg: NzMessageService,
    public changeDetectorRef: ChangeDetectorRef,
    private modal: NzModalService
  ) {}

  ngOnInit(): void {
    this.updateData();
  }

  updateData(): void {
    this.http
      .get<any>('/api/soundWave')
      .toPromise()
      .then((res) => {
        // console.log(res);
        this.listOfData = res;
      });
  }

  showAudioModal(data): void {
    this.selectAudioData = data;
    this.isAudioModalVisible = true;
  }
  editAudioModal(data): void {
    this.selectAudioData = _cloneDeep(data);
    this.addAudioData = _cloneDeep(data);
    this.audioFile = new FormData();
    this.fileListAudio = [{ name: data.audio, uid: '1', filename: data.audio }];
    this.isAddModalVisible = true;
  }
  deleteAudioModal(data): void {
    this.modal.confirm({
      nzTitle: '確定要刪除嗎?',
      nzContent: data.name,
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.http
          .delete<any>('/api/soundWave', { params: { recno: data.recno } })
          .toPromise()
          .then((res) => {
            this.msg.create('success', '刪除成功');
            this.updateData();
          })
          .catch((err) => {
            console.log(err.message);
            this.msg.create('warning', '刪除失敗');
          });
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel'),
    });
  }
  showAddModal(): void {
    this.addAudioData = {
      name: '',
      audio: '',
      url: '',
      // user: 'all',
    };
    this.fileListAudio = [];
    this.audioFile = new FormData();
    this.isAddModalVisible = true;
    this.selectAudioData = null;
  }
  closeAudioModal(): void {
    this.isAudioModalVisible = false;
    this.selectAudioData = null;
  }
  closeAddModal(): void {
    this.isAddModalVisible = false;
    this.selectAudioData = null;
  }

  beforeUploadAudio = (file): boolean => {
    this.fileListAudio = this.fileListAudio.concat(file);
    if (this.fileListAudio.length > 1) {
      this.fileListAudio.shift();
    }
    this.audioFile.set('audio', file);
    return false;
  };

  async submitForm() {
    if (!this.audioFile.has('audio')) {
      this.msg.warning('請選擇聲波檔');
      return false;
    }
    if (!this.addAudioData.name) {
      this.msg.warning('聲波名稱不可空白');
      return false;
    }
    if (!this.addAudioData.url) {
      this.msg.warning('連結不可空白');
      return false;
    }

    let uploadAudioSuccess = false;
    await this.http
      .post<any>('/api/upload/audio', this.audioFile)
      .toPromise()
      .then((res) => {
        const audio = res.url;
        this.msg.create('success', '圖片上傳成功');
        this.addAudioData.audio = audio;
        uploadAudioSuccess = true;
      })
      .catch((error) => {
        console.log(error.message);
        this.msg.create('waring', '圖片上傳失敗');
      });
    if (!uploadAudioSuccess) {
      return false;
    }
    await this.http
      .post<any>('/api/soundWave', this.addAudioData)
      .toPromise()
      .then((res) => {
        this.msg.create('success', '新增成功');
        this.closeAddModal();
        this.updateData();
      })
      .catch((error) => {
        console.log(error.message);
        this.msg.create('waring', '新增失敗');
      });

    console.log(this.addAudioData);
  }

  async putForm() {
    if (!this.addAudioData.name) {
      this.msg.warning('聲波名稱不可空白');
      return false;
    }
    if (!this.addAudioData.url) {
      this.msg.warning('連結不可空白');
      return false;
    }

    if (this.audioFile.get('audio')) {
      let uploadAudioSuccess = false;
      await this.http
        .post<any>('/api/upload/audio', this.audioFile)
        .toPromise()
        .then((res) => {
          const audio = res.url;
          this.msg.create('success', '檔案上傳成功');
          this.addAudioData.audio = audio;
          uploadAudioSuccess = true;
        })
        .catch((error) => {
          console.log(error.message);
          this.msg.create('waring', '檔案上傳失敗');
        });
      if (!uploadAudioSuccess) {
        return false;
      }
    }
    await this.http
      .put<any>('/api/soundWave', this.addAudioData)
      .toPromise()
      .then((res) => {
        this.msg.create('success', '修改成功');
        this.closeAddModal();
        this.updateData();
      })
      .catch((error) => {
        console.log(error.message);
        this.msg.create('waring', '修改失敗');
      });
  }
}
