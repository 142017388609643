<nz-page-header nzBackIcon [nzGhost]="false">
  <nz-page-header-title>廣告內容</nz-page-header-title>
  <nz-page-header-subtitle>-修改 廣告內容</nz-page-header-subtitle>
</nz-page-header>

<nz-table
  nzShowSizeChanger
  [nzFrontPagination]="false"
  [nzLoading]="loading"
  [nzTotal]="totalCount"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  (nzQueryParams)="onAdvertisementQueryParamsChange($event)"
  [nzData]="listOfAdvertisementCurrentPageData"
>
  <thead>
    <tr>
      <th>名稱</th>
      <th>圖片</th>
      <th>連結</th>
      <th>操作</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of listOfAdvertisementCurrentPageData">
      <!-- <td>{{ data.dealerName }}</td> -->
      <td>{{ data.name }}</td>
      <td>
        <img
          (click)="openImage(data.image, 0)"
          [src]="data.image"
          style="height: 50px"
        />
      </td>
      <td>
        <a
          [href]="data.url"
          target="_blank"
          style="
            width: 210px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
          >{{ data.url }}</a
        >
      </td>
      <td>
        <a (click)="editVideoModal(data)">編輯</a>
      </td>
    </tr>
  </tbody>
</nz-table>

<!-- 新增/修改廣告 -->
<nz-modal
  [nzFooter]="null"
  [nzVisible]="isAdvertisementModalVisible"
  [nzTitle]="selectAdvertisementData ? '修改廣告' : '新增廣告'"
  (nzOnCancel)="closeModal()"
>
  <nz-row nzAlign="middle" nzJustify="left" [nzGutter]="[16, 16]">
    <nz-col [nzSm]="6" [nzXs]="24" nzFor="name">
      <span>
        <span style="color: red">*</span>
        廣告名稱：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <input
        disabled
        trim
        nz-input
        [(ngModel)]="addAdvertisementData.name"
        placeholder="名稱"
        name="name"
      />
    </nz-col>

    <nz-col [nzSm]="6" [nzXs]="24" nzFor="image">
      <span>
        <span style="color: red">*</span>
        預覽圖：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <nz-upload
        [nzAccept]="['image/png', 'image/jpeg']"
        [(nzFileList)]="fileListImage"
        [nzBeforeUpload]="beforeUploadImage"
        [nzLimit]="1"
      >
        <button nz-button><i nz-icon nzType="upload"></i>Select File</button>
      </nz-upload>

      <nz-progress
        *ngIf="uploadImagePercent"
        [nzPercent]="uploadImagePercent"
      ></nz-progress>
    </nz-col>

    <nz-col [nzSm]="6" [nzXs]="24" nzFor="wavID">
      <span>
        <span style="color: red">*</span>
        廣告連結：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <input nz-input [(ngModel)]="addAdvertisementData.url" trim name="url" />
    </nz-col>

    <nz-col [nzSpan]="24" style="text-align: center">
      <button
        *ngIf="selectAdvertisementData"
        nz-button
        (click)="putForm()"
        nzType="primary"
      >
        修改
      </button>
    </nz-col>
  </nz-row>
</nz-modal>
