import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedMaterialModuleModule } from '../shared-material-module/shared-material-module.module';

import { WelcomeComponent } from './welcome/welcome.component';
import { VideoComponent } from './video/video.component';
import { QRcodeComponent } from './qrcode/qrcode.component';
import { MarqueeComponent } from './marquee/marquee.component';
import { AdActionComponent } from './ad-action/ad-action.component';
import { SoundWaveComponent } from './sound-wave/sound-wave.component';
import { UploadLogoComponent } from './upload-logo/upload-logo.component';
import { UploadVideoComponent } from './upload-video/upload-video.component';
import { UploadSoundWaveComponent } from './upload-sound-wave/upload-sound-wave.component';
import { SettingQrcodeComponent } from './setting-qrcode/setting-qrcode.component';
import { SettingMarqueeComponent } from './setting-marquee/setting-marquee.component';
import { UploadSoundWave2Component } from './upload-sound-wave2/upload-sound-wave2.component';
import { SoundWave2Component } from './sound-wave2/sound-wave2.component';
import { UserHostComponent } from './user-host/user-host.component';
import { TestComponent } from './test/test.component';
import { VideoWavComponent } from './video-wav/video-wav.component';
import { AdSettingComponent } from './ad-setting/ad-setting.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { AccountSettingComponent } from './account-setting/account-setting.component';
import { HostAccountComponent } from './host-account/host-account.component';
import { RemoveHostComponent } from './remove-host/remove-host.component';
import { SearchHostComponent } from './search-host/search-host.component';

@NgModule({
  declarations: [
    WelcomeComponent,
    SoundWaveComponent,
    VideoComponent,
    QRcodeComponent,
    MarqueeComponent,
    AdActionComponent,
    UploadLogoComponent,
    UploadVideoComponent,
    UploadSoundWaveComponent,
    SettingQrcodeComponent,
    SettingMarqueeComponent,
    UploadSoundWave2Component,
    SoundWave2Component,
    UserHostComponent,
    TestComponent,
    VideoWavComponent,
    AdSettingComponent,
    UserAccountComponent,
    AccountSettingComponent,
    HostAccountComponent,
    RemoveHostComponent,
    SearchHostComponent,
  ],
  imports: [CommonModule, SharedMaterialModuleModule],
})
export class HomeModule {}
