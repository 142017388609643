<div nz-row [nzGutter]="[0, 24]" nzJustify="center" class="text-center">
  <div nz-col nzSpan="24">
    <h4 nz-typography>修改密碼</h4>
  </div>

  <div nz-col nzSpan="24">
    <form nz-form>
      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="oldPassword">
          原密碼
        </nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-input-group
            nzPrefixIcon="lock"
            [nzSuffix]="oldPasswordVisibleTemplate"
          >
            <input
              [type]="oldPasswordVisible ? 'text' : 'password'"
              nz-input
              name="oldPassword"
              nzPlaceHolder="密碼"
              [(ngModel)]="video.oldPassword"
            />
          </nz-input-group>
          <ng-template #oldPasswordVisibleTemplate>
            <i
              nz-icon
              [nzType]="oldPasswordVisible ? 'eye-invisible' : 'eye'"
              (click)="oldPasswordVisible = !oldPasswordVisible"
            ></i>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="newPassword">
          新密碼
        </nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-input-group
            nzPrefixIcon="lock"
            [nzSuffix]="newPasswordVisibleTemplate"
          >
            <input
              [type]="newPasswordVisible ? 'text' : 'password'"
              nz-input
              name="newPassword"
              nzPlaceHolder="密碼"
              [(ngModel)]="video.newPassword"
            />
          </nz-input-group>
          <ng-template #newPasswordVisibleTemplate>
            <i
              nz-icon
              [nzType]="newPasswordVisible ? 'eye-invisible' : 'eye'"
              (click)="newPasswordVisible = !newPasswordVisible"
            ></i>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="newPassword2"
          >確認密碼</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-input-group
            nzPrefixIcon="lock"
            [nzSuffix]="newPassword2VisibleTemplate"
          >
            <input
              [type]="newPassword2Visible ? 'text' : 'password'"
              nz-input
              name="newPassword2"
              nzPlaceHolder="密碼"
              [(ngModel)]="video.newPassword2"
            />
          </nz-input-group>
          <ng-template #newPassword2VisibleTemplate>
            <i
              nz-icon
              [nzType]="newPassword2Visible ? 'eye-invisible' : 'eye'"
              (click)="newPassword2Visible = !newPassword2Visible"
            ></i>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>

    <div nz-col nzSpan="24">
      <button nz-button nzType="primary" (click)="post()">修改</button>
    </div>
  </div>
</div>
