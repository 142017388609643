import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  appState$: Observable<any>;

  constructor(
    private router: Router,
    private msg: NzMessageService,
    public appService: AppService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // guard 守衛
    // 開發時忽律guard
    // console.log('router  guard');
    // return true;

    const userRouter = [
      'AdAction',
      'Marquee',
      'QRcode',
      'SoundWave2',
      'VideoWav',
      'Welcome',
      'UserAccount',
    ];
    const adminRouter = [
      'AdSetting',
      'UploadLogo',
      'UploadVideo',
      'UploadSoundWave2',
      'SettingMarquee',
      'SettingQrcode',
      'UserHost',
      'HostAccount',
      'RemoveHost',
      'SearchHost',
    ].concat(userRouter);
    const rootRouter = [].concat(adminRouter);

    const NextPath = next.routeConfig.path;
    const JWT = this.appService.$getCookie('JWT');

    if (JWT !== '') {
      // jwt 的 payload 是用base64編碼過了 所以用atob去decode
      const payload = JSON.parse(atob(JWT.split('.')[1]));
      const nowTime = new Date().getTime();
      const exp = new Date(payload.exp * 1000).getTime();
      const auth = payload.auth;
      const user = payload.user;

      if (nowTime > exp) {
        console.log('token 超過時效');
        this.msg.warning('請重新登入');
        this.router.navigate(['/Welcome']);
        return false;
      } else {
        if (auth === '3') {
          if (userRouter.indexOf(NextPath) === -1) {
            this.msg.warning('權限不足');
            this.router.navigate(['/Welcome']);
            return false;
          }
        } else if (auth === '2') {
          if (adminRouter.indexOf(NextPath) === -1) {
            this.msg.warning('權限不足');
            this.router.navigate(['/Welcome']);
            return false;
          } else {
            if (
              (NextPath === 'RemoveHost' || NextPath === 'SearchHost') &&
              user !== 'umtv'
            ) {
              this.msg.warning('權限不足');
              this.router.navigate(['/Welcome']);
              return false;
            }
          }
        } else if (auth === '1') {
        }
        return true;
      }
    } else {
      this.msg.warning('請登入');
      this.router.navigate(['/Welcome']);
      return false;
    }
  }
}
