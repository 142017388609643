<nz-page-header nzBackIcon [nzGhost]="false">
  <nz-page-header-title>廣告二維碼</nz-page-header-title>
  <nz-page-header-subtitle>-新增/修改 廣告二維碼內容</nz-page-header-subtitle>
  <nz-page-header-extra>
    <button
      (click)="showAddModal()"
      nz-button
      nzType="primary"
      nzShape="circle"
    >
      <i nz-icon nzType="plus" nzTheme="outline"></i>
    </button>
  </nz-page-header-extra>
</nz-page-header>

<nz-table
  nzShowSizeChanger
  [nzFrontPagination]="false"
  [nzLoading]="loading"
  [nzTotal]="totalCount"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  (nzQueryParams)="onQueryParamsChange($event)"
  [nzData]="listOfCurrentPageData"
>
  <thead>
    <tr>
      <th>內容</th>
      <th>操作</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of listOfCurrentPageData">
      <!-- <td>{{ data.user }}</td> -->
      <td>{{ data.content }}</td>
      <td style="width: 110px;">
        <a (click)="editAudioModal(data)">編輯</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a (click)="deleteAudioModal(data)">刪除</a>
      </td>
    </tr>
  </tbody>
</nz-table>

<!-- 廣告二維碼內容 -->
<nz-modal
  [nzFooter]="null"
  [nzVisible]="isQrcodeModalVisible"
  [nzTitle]="selectQrcodeData ? '修改廣告二維碼內容' : '新增廣告二維碼內容'"
  (nzOnCancel)="closeAddModal()"
>
  <form nz-form>
    <nz-form-item>
      <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="content"
        >二維碼內容</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <input
          nz-input
          [(ngModel)]="addQrcodeData.content"
          trim
          name="content"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control
        [nzXs]="{ span: 24, offset: 0 }"
        [nzSm]="{ span: 16, offset: 6 }"
      >
        <button
          *ngIf="selectQrcodeData"
          nz-button
          (click)="putForm()"
          nzType="primary"
        >
          修改
        </button>
        <button
          *ngIf="!selectQrcodeData"
          nz-button
          (click)="submitForm()"
          nzType="primary"
        >
          新增
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>
