import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../app.service';
import sha1 from 'crypto-js/sha1';

interface ChangePassowrd {
  account;
  oldPassword;
  newPassword;
  newPassword2;
}

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
})
export class UserAccountComponent implements OnInit {
  video: ChangePassowrd = {
    account: '',
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
  };
  oldPasswordVisible = false;
  newPasswordVisible = false;
  newPassword2Visible = false;
  listOfVideoOption: Array<{ label: string; value: string }> = [];
  listOfOption: Array<{ label: string; value: number }> = [];
  dealerOptions = [];

  constructor(private http: HttpClient, public appService: AppService) {}

  ngOnInit(): void {
    const JWT = this.appService.$getCookie('JWT');

    if (JWT !== '') {
      // jwt 的 payload 是用base64編碼過了 所以用atob去decode
      const payload = JSON.parse(atob(JWT.split('.')[1]));
      const nowTime = new Date().getTime();
      this.video.account = payload.user;
    }
  }

  post(): void {
    if (
      !this.video.oldPassword ||
      !this.video.newPassword ||
      !this.video.newPassword2
    ) {
      this.appService.showMessage('warning', '必填欄位不能空白');
      return;
    }
    if (this.video.newPassword !== this.video.newPassword2) {
      this.appService.showMessage('warning', '請再次確認密碼');
      return;
    }

    const obj = {
      account: this.video.account,
      oldPassword: sha1(this.video.oldPassword).toString(),
      newPassword: sha1(this.video.newPassword).toString(),
    };

    this.http
      .put<any>('/api/resetPassword', obj)
      .toPromise()
      .then((res) => {
        this.appService.showMessage('success', '修改成功');
      })
      .catch((err) => {
        // console.log(err.data.message);
        if (err.status === 400) {
          this.appService.showMessage('warning', '密碼錯誤');
        } else {
          this.appService.showMessage('warning', '修改失敗');
        }
      });
  }
}
