<div nz-row [nzGutter]="[0, 24]" nzJustify="center" class="text-center">
  <div nz-col nzSpan="24">
    <h4 nz-typography>透過設定，可以即時操控本主機的廣告二維碼</h4>
  </div>

  <div nz-col nzSpan="24">
    <form nz-form>
      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="user"
          >經銷商</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-select
            [(ngModel)]="qrcode.multipleDealer"
            (ngModelChange)="onChangeDealer($event)"
            nzMode="multiple"
            nzPlaceHolder="選擇經銷商"
            name="dealer"
          >
            <nz-option nzValue="all" nzLabel="all"></nz-option>
            <nz-option
              *ngFor="let option of appService.$getDealerList()"
              [nzLabel]="option.name"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="setQRText"
          >二維碼內文
          <button
            (click)="showAddModal()"
            nz-button
            nzType="primary"
            nzShape="circle"
            nzSize="small"
          >
            <i nz-icon nzType="plus" nzTheme="outline"></i>
          </button>
        </nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-select
            name="text"
            nzShowSearch
            nzServerSearch
            nzPlaceHolder="搜尋內容"
            [nzShowArrow]="false"
            (nzOnSearch)="searchOptions($event)"
            [(ngModel)]="qrcode.setQRText"
          >
            <nz-option
              *ngFor="let option of listOfOption"
              [nzLabel]="option.label"
              [nzValue]="option.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="setQRPos"
          >二維碼位置</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24" [nzSpan]="18">
          <nz-radio-group [(ngModel)]="qrcode.setQRPos" name="setQRPos">
            <label nz-radio nzValue="1">螢幕上方</label>
            <label nz-radio nzValue="2">螢幕下方</label>
            <label nz-radio nzValue="3">螢幕右方</label>
            <label nz-radio nzValue="4">螢幕左方</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </form>

    <div nz-col nzSpan="24">
      <button nz-button nzType="primary" (click)="post()">確定</button>
    </div>
  </div>
</div>

<!-- 廣告二維碼內容 -->
<nz-modal
  [nzFooter]="null"
  [nzVisible]="isQrcodeModalVisible"
  nzTitle="新增廣告二維碼內容"
  (nzOnCancel)="closeAddModal()"
>
  <form nz-form>
    <nz-form-item>
      <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="content"
        >二維碼內容</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <input
          nz-input
          [(ngModel)]="addQrcodeData.content"
          trim
          name="content"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control
        [nzXs]="{ span: 24, offset: 0 }"
        [nzSm]="{ span: 16, offset: 6 }"
      >
        <button nz-button (click)="submitForm()" nzType="primary">新增</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>
