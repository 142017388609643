<nz-page-header nzBackIcon [nzGhost]="false">
  <nz-page-header-title>主機logo</nz-page-header-title>
  <nz-page-header-subtitle>-修改 logo圖片</nz-page-header-subtitle>
</nz-page-header>

<nz-table
  nzShowSizeChanger
  [nzFrontPagination]="false"
  [nzLoading]="loading"
  [nzTotal]="totalCount"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  (nzQueryParams)="onQueryParamsChange($event)"
  [nzData]="listOfCurrentPageData"
>
  <thead>
    <tr>
      <th>經銷商</th>
      <th>圖片</th>
      <th>操作</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of listOfCurrentPageData; index as i">
      <td>{{ data.name }}</td>

      <td>
        <a>
          <img
            [src]="data.image"
            (click)="openImage(data.image, i)"
            style="height: 50px"
            alt="logo圖片"
          />
        </a>
      </td>

      <td>
        <a (click)="showEditModal(data)">編輯</a>
      </td>
    </tr>
  </tbody>
</nz-table>

<!-- logo圖片 -->
<nz-modal
  *ngIf="selectedData"
  [nzFooter]="null"
  [nzVisible]="isVisible"
  [nzTitle]="'修改圖片-' + selectedData.name"
  (nzOnCancel)="closeEditModal()"
>
  <div nz-row nzJustify="center" class="text-center">
    <h4 nz-typography>選擇圖片</h4>
  </div>

  <div nz-row>
    <div nz-col nzSpan="18" nzOffset="3">
      <nz-upload
        nzType="drag"
        [nzAccept]="['image/png', 'image/jpeg']"
        [nzShowUploadList]="false"
        [nzMultiple]="true"
        [nzBeforeUpload]="beforeUpload"
      >
        <p class="ant-upload-drag-icon">
          <i nz-icon nzType="inbox" nzTheme="outline"></i>
        </p>
        <p class="ant-upload-text">點擊或拖拉圖片</p>
        <p class="ant-upload-hint">
          只能選擇圖片檔(image/png, image/jpeg)且大小不得超過2MB
        </p>
      </nz-upload>
    </div>
  </div>

  <ng-template #changeLogo>
    <nz-divider nzText="Logo圖片"></nz-divider>

    <div nz-row nzJustify="center" nzAlign="middle">
      <div nz-col nzSpan="18">
        <div class="response-img">
          <a>
            <img
              (click)="openImage(selectedData.image, 0)"
              [src]="selectedData.image"
              style="height: 120px"
            />
          </a>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="newImage; else changeLogo">
    <nz-divider nzText="Logo圖片更換"></nz-divider>

    <div
      nz-row
      nzJustify="center"
      nzAlign="middle"
      [nzGutter]="[8, 8]"
      style="text-align: center"
    >
      <div nz-col nzSpan="10">
        <div class="response-img">
          <a>
            <img
              (click)="openImage(selectedData.image, 0)"
              [src]="selectedData.image"
              style="height: 100px"
            />
          </a>
        </div>
      </div>

      <div nz-col nzSpan="2">
        <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
      </div>

      <div nz-col nzSpan="10" nz-col>
        <div class="response-img">
          <a>
            <img [src]="newImage" style="height: 100px" />
          </a>
        </div>
      </div>

      <div nz-col nzSpan="24">
        <button nz-button nzType="primary" (click)="modify()">確定</button>
      </div>
    </div>
  </div>
</nz-modal>
