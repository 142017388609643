import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-ad-action',
  templateUrl: './ad-action.component.html',
  styleUrls: ['./ad-action.component.scss'],
})
export class AdActionComponent implements OnInit {
  constructor(private http: HttpClient, public appService: AppService) {}
  multipleDealer = ['all'];
  ngOnInit(): void {
    this.multipleDealer = this.appService.multipleDealer;
  }

  getDealerList(): any[] {
    return this.appService.dealerList;
  }

  onChangeDealer(val): void {
    const lastValue = val[val.length - 1];
    if (lastValue === 'all') {
      this.multipleDealer = ['all'];
    } else {
      const index = val.indexOf('all');
      if (index !== -1) {
        val.splice(index, 1);
      }
      this.multipleDealer = [...val];
    }

    this.appService.setMultipleDealer(this.multipleDealer);
  }
  adStart(): void {
    this.appService.onLoading(true);

    this.http
      .get<any>('/api/sendOP', {
        params: { multipleDealer: this.multipleDealer, op: 'ADON' },
      })
      .toPromise()
      .then((res) => {})
      .catch((err) => {});

    setTimeout(() => {
      this.appService.showMessage('warning', '已送出指令');
      this.appService.onLoading(false);
    }, 1000);
  }
  adStop(): void {
    this.appService.onLoading(true);

    this.http
      .get<any>('/api/sendOP', {
        params: { multipleDealer: this.multipleDealer, op: 'ADOFF' },
      })
      .toPromise()
      .then((res) => {})
      .catch((err) => {});

    setTimeout(() => {
      this.appService.showMessage('warning', '已送出指令');
      this.appService.onLoading(false);
    }, 1000);
  }
}
