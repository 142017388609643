<div nz-row [nzGutter]="[0, 24]" nzJustify="center" class="text-center">
  <div nz-col nzSpan="24">
    <h4 nz-typography>查詢帳號</h4>
  </div>

  <div nz-col nzSpan="24">
    <form nz-form>
      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="email"
          >信箱/用戶名</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <input nz-input [(ngModel)]="email" trim name="email" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control
          [nzXs]="{ span: 24, offset: 0 }"
          [nzSm]="{ span: 24, offset: 0 }"
        >
          <button nz-button (click)="searchHost()" nzType="primary">
            查詢
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
