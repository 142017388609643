import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppService } from '../../app.service';
import sha1 from 'crypto-js/sha1';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  passwordVisible = false;
  password: string;
  account: string;
  validateForm!: FormGroup;

  constructor(
    private modal: NzModalRef,
    private http: HttpClient,
    private fb: FormBuilder,
    private msg: NzMessageService,
    public appService: AppService
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      account: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  submitForm(): void {
    // console.log(this.validateForm.value);
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.invalid) {
      this.msg.create('warning', '帳號/密碼不能為空');
      // console.log(this.validateForm.get('account').setValue('bbb'));
      return;
    }

    // console.log(this.validateForm.value.account);
    const post = {
      account: this.validateForm.get('account').value,
      password: sha1(this.validateForm.get('password').value).toString(),
    };

    this.http
      .post<any>('/api/login', post)
      .toPromise()
      .then((res) => {
        this.msg.success('登入成功');
        this.appService.changeAuth(res.auth);
        this.modal.destroy();
      })
      .catch((error) => {
        this.msg.create('warning', '帳號/密碼錯誤');
        // console.log(error.message);
      });
  }
}
