import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-search-host',
  templateUrl: './search-host.component.html',
  styleUrls: ['./search-host.component.scss'],
})
export class SearchHostComponent implements OnInit {
  email = '';

  constructor(private http: HttpClient, public appService: AppService) {}

  ngOnInit(): void {}

  searchHost(): void {
    if (this.email === '') {
      this.appService.showMessage('warning', '必填欄位不能空白');
      return;
    }

    this.http
      .post('api/searchHost', { email: this.email }, { responseType: 'text' })
      // .get('api/searchHost', { responseType: 'text' })
      .toPromise()
      .then((res) => {
        // console.log(res);
        // Fail 501 資料輸入有誤
        // Fail 502 電子郵件或密碼錯誤
        // Fail 503 電子郵件重複註冊
        // Fail 504 您無權限卸載此帳戶
        // Success
        // 主機卸載成功

        if (res.indexOf('Fail 501') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '查詢失敗：' + message);
        } else if (res.indexOf('Fail 502') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '查詢失敗：' + message);
        } else if (res.indexOf('Fail 503') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '查詢失敗：' + message);
        } else if (res.indexOf('Fail 504') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '查詢失敗：' + message);
        } else if (res.indexOf('finebnb') > -1) {
          const jsonArray = JSON.parse(res.substr(8));
          jsonArray.forEach((element) => {
            this.appService.showMessage(
              'success',
              `信箱：${element.Email}，帳號：${element.Account}`
            );
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        this.appService.showMessage('warning', '查詢失敗，請稍後再試一次');
      });
  }
}
