<div nz-row nzJustify="center" class="text-center">
  <div nz-col nzSpan="24"><h1>總公司管理系統</h1></div>

  <div nz-col nzSpan="24">
    <h3>請先登入：</h3>
    <h3>
      管理員身份可以進行廣告設定，創建帳號
      <!-- <a (click)="goTo('/UploadVideo')">影片</a> -->
    </h3>
    <h3>
      一般使用者只能進行功能操作
      <!-- <a (click)="goTo('/UploadSoundWave')">聲波</a> -->
    </h3>
  </div>

  <!-- <div nz-col nzSpan="24">
    <nz-divider nzText="更新項目"></nz-divider>
  </div>

  <pre>
2020/09/02 - 加上分頁功能，以及自動搜尋
  </pre> -->
</div>
