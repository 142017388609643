import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WelcomeComponent } from './home/welcome/welcome.component';
import { VideoComponent } from './home/video/video.component';
import { QRcodeComponent } from './home/qrcode/qrcode.component';
import { MarqueeComponent } from './home/marquee/marquee.component';
import { AdActionComponent } from './home/ad-action/ad-action.component';
import { SoundWaveComponent } from './home/sound-wave/sound-wave.component';
import { UploadLogoComponent } from './home/upload-logo/upload-logo.component';
import { UploadVideoComponent } from './home/upload-video/upload-video.component';
import { UploadSoundWaveComponent } from './home/upload-sound-wave/upload-sound-wave.component';
import { SettingQrcodeComponent } from './home/setting-qrcode/setting-qrcode.component';
import { SettingMarqueeComponent } from './home/setting-marquee/setting-marquee.component';
import { UploadSoundWave2Component } from './home/upload-sound-wave2/upload-sound-wave2.component';
import { SoundWave2Component } from './home/sound-wave2/sound-wave2.component';
import { UserHostComponent } from './home/user-host/user-host.component';
import { VideoWavComponent } from './home/video-wav/video-wav.component';
import { AdSettingComponent } from './home/ad-setting/ad-setting.component';
import { UserAccountComponent } from './home/user-account/user-account.component';
import { AccountSettingComponent } from './home/account-setting/account-setting.component';
import { HostAccountComponent } from './home/host-account/host-account.component';
import { RemoveHostComponent } from './home/remove-host/remove-host.component';
import { SearchHostComponent } from './home/search-host/search-host.component';
import { TestComponent } from './home/test/test.component';

import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'Welcome' },
  {
    path: 'Welcome',
    component: WelcomeComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'Video',
    component: VideoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'QRcode',
    component: QRcodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Marquee',
    component: MarqueeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'AdAction',
    component: AdActionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'SoundWave',
    component: SoundWaveComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'UploadLogo',
    component: UploadLogoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'UploadVideo',
    component: UploadVideoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'UploadSoundWave',
    component: UploadSoundWaveComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'SettingQrcode',
    component: SettingQrcodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'SettingMarquee',
    component: SettingMarqueeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'UploadSoundWave2',
    component: UploadSoundWave2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'SoundWave2',
    component: SoundWave2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'UserHost',
    component: UserHostComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'VideoWav',
    component: VideoWavComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'AdSetting',
    component: AdSettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'UserAccount',
    component: UserAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'AccountSetting',
    component: AccountSettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'HostAccount',
    component: HostAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'RemoveHost',
    component: RemoveHostComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'SearchHost',
    component: SearchHostComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Test',
    component: TestComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
