import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatButtonModule } from '@angular/material/button';
// import { MatIconModule } from '@angular/material/icon';
// import { MatCardModule } from '@angular/material/card';
// import { MatGridListModule } from '@angular/material/grid-list';
// import { MatDividerModule } from '@angular/material/divider';
// import { MatMenuModule } from '@angular/material/menu';
// import { MatListModule } from '@angular/material/list';

import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

// import { MatVideoModule } from 'mat-video';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LightboxModule } from 'ngx-lightbox';
import { InputTrimModule } from 'ng2-trim-directive';

@NgModule({
  exports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    // MatToolbarModule,
    // MatButtonModule,
    // MatIconModule,
    // MatCardModule,
    // MatGridListModule,
    // MatDividerModule,
    // MatMenuModule,
    // MatListModule,
    NzSwitchModule,
    NzGridModule,
    NzTypographyModule,
    NzFormModule,
    NzRadioModule,
    NzSelectModule,
    NzUploadModule,
    NzIconModule,
    NzDividerModule,
    NzButtonModule,
    NzMessageModule,
    NzTableModule,
    NzDropDownModule,
    NzModalModule,
    NzInputModule,
    NzPageHeaderModule,
    NzAutocompleteModule,
    NzTagModule,
    NzNoAnimationModule,
    NzSpinModule,
    NzProgressModule,
    NzTabsModule,
    // MatVideoModule,
    // MatSliderModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    LightboxModule,
    InputTrimModule,
  ],
})
export class SharedMaterialModuleModule {}
