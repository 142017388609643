import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  auth = '0'; // 1:root 2:admin 3:user
  multipleDealer = ['all'];
  isLoading = false;
  dealerList = [];

  constructor(private msg: NzMessageService) {
    this.init();
  }

  init(): void {}

  // 改變使用者權限
  changeAuth(str): void {
    this.auth = str;
  }

  // 遮罩狀態
  onLoading(status): void {
    this.isLoading = status;
  }

  // 顯示message
  showMessage(type, str): void {
    this.msg.create(type, str);
  }

  setMultipleDealer(array): void {
    if (array.length === 0) {
      array = ['all'];
    }
    this.multipleDealer = array;
  }

  $setDealerList(array): void {
    this.dealerList = array;
  }
  $getDealerList(): any[] {
    return this.dealerList;
  }

  $getCookie(name): string {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    } else {
      return '';
    }
  }
}
