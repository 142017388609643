import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../app.service';

interface SoundWave {
  multipleDealer;
  sendWAVID;
}

@Component({
  selector: 'app-sound-wave2',
  templateUrl: './sound-wave2.component.html',
  styleUrls: ['./sound-wave2.component.scss'],
})
export class SoundWave2Component implements OnInit {
  soundWave: SoundWave = {
    multipleDealer: ['all'],
    sendWAVID: '',
  };
  listOfOption: Array<{ label: string; value: number }> = [];
  dealerOptions = [];
  isSpinning = false;

  constructor(private http: HttpClient, public appService: AppService) {}

  ngOnInit(): void {
    this.soundWave.multipleDealer = this.appService.multipleDealer;
  }

  searchOptions(value: string): void {
    this.http
      .jsonp<{ result: Array<{ recno: number; name: string }> }>(
        `/api/jsonp?key=soundWave2&searchText=${value}`,
        'callback'
      )
      .subscribe((data) => {
        const listOfOption: Array<{ value: number; label: string }> = [];
        data.result.forEach((item) => {
          listOfOption.push({
            value: item.recno,
            label: item.name,
          });
        });
        this.listOfOption = listOfOption;
      });
  }

  onChangeDealer(val): void {
    const lastValue = val[val.length - 1];
    if (lastValue === 'all') {
      this.soundWave.multipleDealer = ['all'];
    } else {
      const index = val.indexOf('all');
      if (index !== -1) {
        val.splice(index, 1);
      }
      this.soundWave.multipleDealer = [...val];
    }

    this.appService.setMultipleDealer(this.soundWave.multipleDealer);
  }
  post(): void {
    if (!this.soundWave.sendWAVID) {
      this.appService.showMessage('warning', '必填欄位不能空白');
      return;
    }

    this.appService.onLoading(true);

    this.http
      .get<any>('/api/sendOP', {
        params: { ...this.soundWave },
      })
      .toPromise()
      .then((res) => {})
      .catch((err) => {});

    setTimeout(() => {
      this.appService.showMessage('warning', '已送出指令');
      this.appService.onLoading(false);
    }, 1000);
  }
}
