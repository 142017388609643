<div nz-row [nzGutter]="[0, 24]" nzJustify="center" class="text-center">
  <div nz-col nzSpan="24">
    <h4 nz-typography>解除主機綁定</h4>
  </div>

  <div nz-col nzSpan="24">
    <form nz-form>
      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="email"
          >信箱</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <input nz-input [(ngModel)]="HostAccount.email" trim name="email" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="pass"
          >密碼</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
            <input
              [type]="passwordVisible ? 'text' : 'password'"
              nz-input
              placeholder="密碼"
              name="pass"
              [(ngModel)]="HostAccount.pass"
            />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <i
              nz-icon
              [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
              (click)="passwordVisible = !passwordVisible"
            ></i>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control
          [nzXs]="{ span: 24, offset: 0 }"
          [nzSm]="{ span: 24, offset: 0 }"
        >
          <button nzType="danger" nz-button (click)="removeHost()">解綁</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
