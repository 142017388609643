import { Component, OnInit } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
  HttpEventType,
} from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  uploading = false;
  fileList: NzUploadFile[] = [];
  videoFile: FormData = new FormData();

  constructor(private http: HttpClient, private msg: NzMessageService) {}

  ngOnInit(): void {}

  beforeUpload = (file): boolean => {
    if (file.size > 200 * 1024 * 1000) {
      this.msg.warning('影片太大，不得超過200MB');
      return false;
    }

    this.fileList = this.fileList.concat(file);
    if (this.fileList.length > 1) {
      this.fileList.shift();
    }
    this.videoFile.set('video', file);
    return false;
  };

  async handleUpload() {
    const header = new HttpHeaders();

    const req = new HttpRequest(
      'POST',
      'http://soundwave.umtv.com.tw/api/upload/image2',
      this.videoFile,
      {
        headers: header,
        reportProgress: true,
      }
    );

    await this.http.request(req).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log('UploadProgress');
          console.log(event);
          const total = ((event.loaded / event.total) * 100).toFixed(2);
          console.log('upload');
          console.log(total);
        }

        if (event.type === HttpEventType.Response) {
          console.log('Response');
          console.log(event);
          console.log(event.body);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
