<nz-page-header nzBackIcon [nzGhost]="false">
  <nz-page-header-title>廣告內容</nz-page-header-title>
  <nz-page-header-subtitle>-修改 廣告內容</nz-page-header-subtitle>
</nz-page-header>

<nz-table
  nzShowSizeChanger
  [nzFrontPagination]="false"
  [nzLoading]="loading"
  [nzTotal]="totalCount"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  (nzQueryParams)="onAdvertisementQueryParamsChange($event)"
  [nzData]="listOfAdvertisementCurrentPageData"
>
  <thead>
    <tr>
      <th>帳號</th>
      <th>權限</th>
      <th>操作</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of listOfAdvertisementCurrentPageData">
      <!-- <td>{{ data.dealerName }}</td> -->
      <td>{{ data.account }}</td>

      <td>
        <span *ngIf="data.auth === '2'">管理員</span>
        <span *ngIf="data.auth === '3'">一般使用者</span>
      </td>
      <td>
        <a (click)="modifyAuthModal(data)">修改權限</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a (click)="modifyPasswordModal(data)">修改密碼</a>
      </td>
    </tr>
  </tbody>
</nz-table>

<!-- 修改權限 -->
<nz-modal
  [nzFooter]="null"
  [nzVisible]="isAdvertisementModalVisible"
  [nzTitle]="'修改權限'"
  (nzOnCancel)="closeModifyAuthModal()"
>
  <nz-row nzAlign="middle" nzJustify="center" [nzGutter]="[16, 16]">
    <nz-col [nzSm]="24" [nzXs]="24" name="name" style="text-align: center">
      <nz-select [(ngModel)]="addAdvertisementData.auth" style="width: 150px">
        <nz-option nzValue="2" nzLabel="管理員"></nz-option>
        <nz-option nzValue="3" nzLabel="一般使用者"></nz-option>
      </nz-select>
    </nz-col>

    <nz-col [nzSpan]="24" style="text-align: center">
      <button
        *ngIf="selectAdvertisementData"
        nz-button
        (click)="putAccountAuth()"
        nzType="primary"
      >
        修改
      </button>
    </nz-col>
  </nz-row>
</nz-modal>

<!-- 修改密碼 -->
<nz-modal
  [nzFooter]="null"
  [nzVisible]="isAdvertisementModalVisible2"
  [nzTitle]="'修改密碼'"
  (nzOnCancel)="closeModifyPasswordModal()"
>
  <nz-row nzAlign="middle" nzJustify="center" [nzGutter]="[16, 16]">
    <nz-col [nzSm]="24" [nzXs]="24" name="name" style="text-align: center">
      <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate2">
        <input
          [type]="passwordVisible ? 'text' : 'password'"
          nz-input
          placeholder="新密碼"
          [(ngModel)]="newPassword"
        />
      </nz-input-group>
      <ng-template #suffixTemplate2>
        <i
          nz-icon
          [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
          (click)="passwordVisible = !passwordVisible"
        ></i>
      </ng-template>
    </nz-col>

    <nz-col [nzSpan]="24" style="text-align: center">
      <button
        *ngIf="selectAdvertisementData"
        nz-button
        (click)="putAccountPassword()"
        nzType="primary"
      >
        修改
      </button>
    </nz-col>
  </nz-row>
</nz-modal>
