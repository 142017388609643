import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { LoginComponent } from './components/login/login.component';
import { UserAddComponent } from './components/user-add/user-add.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Output() sidenavClick = new EventEmitter();
  isCollapsed = false;
  isSpinning = true;

  constructor(
    private router: Router,
    private modalService: NzModalService,
    private message: NzMessageService,
    private http: HttpClient,
    public appService: AppService
  ) {}

  ngOnInit(): void {
    // 每次一開始先更新經銷商
    this.http
      .get<any>('/api/getDealerList')
      .toPromise()
      .then((res) => {
        // console.log(res);
        this.appService.$setDealerList(res);
      })
      .catch((err) => {
        // console.log(err.message);
      });

    const JWT = this.appService.$getCookie('JWT');

    if (JWT !== '') {
      // jwt 的 payload 是用base64編碼過了 所以用atob去decode
      const payload = JSON.parse(atob(JWT.split('.')[1]));
      const nowTime = new Date().getTime();
      const exp = new Date(payload.exp * 1000).getTime();
      const auth = payload.auth;

      if (nowTime > exp) {
        this.appService.changeAuth('0');
      } else {
        if (auth === '3') {
          this.appService.changeAuth('3');
        } else if (auth === '2') {
          this.appService.changeAuth('2');
        } else if (auth === '1') {
          this.appService.changeAuth('1');
        }
      }
    }
  }

  getAuth(): string {
    return this.appService.auth;
  }

  getIsLoading(): boolean {
    return this.appService.isLoading;
  }

  sidenav_click(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  close_nav(): void {
    if (document.body.clientWidth <= 767) {
      this.isCollapsed = true;
    }
  }

  showModal(): void {
    this.modalService.create({
      nzTitle: '登入',
      nzFooter: null,
      nzContent: LoginComponent,
      nzOnCancel: () => {},
    });
  }

  showUserAddModal(): void {
    this.modalService.create({
      nzTitle: '新增',
      nzFooter: null,
      nzContent: UserAddComponent,
      nzOnCancel: () => {},
    });
  }

  logOut(): void {
    document.cookie =
      'JWT=;' +
      'path=/;' +
      'domain=.' +
      location.host +
      ';expires=' +
      new Date(0).toUTCString();

    document.cookie = 'JWT=;' + ';expires=' + new Date(0).toUTCString();

    this.router.navigate(['/Welcome']);
    this.appService.changeAuth('0');
    this.appService.showMessage('success', '已登出');
  }
}
