<nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
  <button nz-button><i nz-icon nzType="upload"></i>Select File</button>
</nz-upload>
<button
  nz-button
  [nzType]="'primary'"
  [nzLoading]="uploading"
  (click)="handleUpload()"
  [disabled]="fileList.length == 0"
  style="margin-top: 16px;"
>
  {{ uploading ? "Uploading" : "Start Upload" }}
</button>
