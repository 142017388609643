import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from '../../app.service';

interface HostAccount {
  email: string;
  pass: string;
}

@Component({
  selector: 'app-remove-host',
  templateUrl: './remove-host.component.html',
  styleUrls: ['./remove-host.component.scss'],
})
export class RemoveHostComponent implements OnInit {
  HostAccount: HostAccount = {
    email: '',
    pass: '',
  };
  passwordVisible = false;

  constructor(private http: HttpClient, public appService: AppService) {}

  ngOnInit(): void {}

  removeHost(): void {
    if (this.HostAccount.pass === '' || this.HostAccount.email === '') {
      this.appService.showMessage('warning', '必填欄位不能空白');
      return;
    }
    const emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    // console.log(this.HostAccount.email.match(emailRule));
    if (this.HostAccount.email.match(emailRule) === null) {
      this.appService.showMessage('warning', 'email格式不對');
      return;
    }

    this.http
      .post('api/removeHost', this.HostAccount, { responseType: 'text' })
      // .get('api/removeHost', { responseType: 'text' })
      .toPromise()
      .then((res) => {
        // console.log(res);
        // Fail 501 資料輸入有誤
        // Fail 502 電子郵件或密碼錯誤
        // Fail 503 電子郵件重複註冊
        // Fail 504 您無權限卸載此帳戶
        // Success 主機卸載成功

        if (res.indexOf('Fail 501') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '解綁失敗：' + message);
        } else if (res.indexOf('Fail 502') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '解綁失敗：' + message);
        } else if (res.indexOf('Fail 503') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '解綁失敗：' + message);
        } else if (res.indexOf('Fail 504') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '解綁失敗：' + message);
        } else if (res.indexOf('主機卸載成功') > -1) {
          this.appService.showMessage('success', '主機卸載成功');
        } else {
          this.appService.showMessage('warning', '解綁失敗，請稍後再試一次');
        }
      })
      .catch((err) => {
        // console.log(err);
        this.appService.showMessage('warning', '解綁失敗，請稍後再試一次');
      });
  }
}
