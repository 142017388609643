<div nz-row [nzGutter]="[0, 24]" nzJustify="center" class="text-center">
  <div nz-col nzSpan="24">
    <h4 nz-typography>開啟或關閉廣告</h4>
  </div>

  <div nz-col nzSpan="24">
    <form nz-form>
      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="dealer"
          >經銷商</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <!-- <nz-select [(ngModel)]="dealer" name="dealer">
            <nz-option nzValue="all" nzLabel="all"></nz-option>
            <nz-option
              *ngFor="let item of dealerOptions"
              [nzValue]="item"
              [nzLabel]="item"
            ></nz-option>
          </nz-select> -->

          <nz-select
            [(ngModel)]="multipleDealer"
            (ngModelChange)="onChangeDealer($event)"
            nzMode="multiple"
            nzPlaceHolder="選擇經銷商"
            name="dealer"
          >
            <nz-option nzValue="all" nzLabel="all"></nz-option>
            <nz-option
              *ngFor="let option of appService.$getDealerList()"
              [nzLabel]="option.name"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="action"
          >操作</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <button
            (click)="adStart()"
            nz-button
            nzType="primary"
            nzShape="circle"
            name="action"
          >
            開
          </button>
          <button
            (click)="adStop()"
            nz-button
            nzType="danger"
            nzShape="circle"
            name="action"
          >
            關
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
