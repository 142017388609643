<nz-tabset>
  <nz-tab nzTitle="影片內容" (nzClick)="chageTab('1')">
    <nz-page-header nzBackIcon [nzGhost]="false">
      <nz-page-header-title>影片內容</nz-page-header-title>
      <nz-page-header-subtitle>-上傳/修改 影片內容</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button
          (click)="showAddModal()"
          nz-button
          nzType="primary"
          nzShape="circle"
        >
          <i nz-icon nzType="plus" nzTheme="outline"></i>
        </button>
      </nz-page-header-extra>
    </nz-page-header>

    <nz-table
      nzShowSizeChanger
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      [nzTotal]="totalCountForVideo"
      [nzPageSize]="pageSizeForVideo"
      [nzPageIndex]="pageIndexForVideo"
      (nzQueryParams)="onVideoQueryParamsChange($event)"
      [nzData]="listOfVideoCurrentPageData"
    >
      <thead>
        <tr>
          <!-- <th>經銷商</th> -->
          <th>名稱</th>
          <th>分類</th>
          <th>關鍵字</th>
          <th>預覽圖</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of listOfVideoCurrentPageData">
          <!-- <td>{{ data.dealerName }}</td> -->
          <td>{{ data.name }}</td>
          <td>{{ data.categoryName }}</td>
          <td *ngIf="data.keywords.length > 0">
            <span *ngFor="let keyword of data.keywords; let i = index"
              >{{ keyword }}
              <span *ngIf="i != data.keywords.length - 1">，</span>
            </span>
          </td>
          <td *ngIf="data.keywords.length == 0">
            <span>無</span>
          </td>
          <td>
            <img
              (click)="openImage(data.image, 0)"
              [src]="data.image"
              style="height: 50px"
            />
          </td>
          <td>
            <a (click)="showVideoModal(data)">預覽</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a (click)="editVideoModal(data)">編輯</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a (click)="deleteVideoModal(data)">刪除</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>

  <nz-tab nzTitle="影片分類" (nzClick)="chageTab('2')">
    <nz-page-header nzBackIcon [nzGhost]="false">
      <nz-page-header-title>影片分類</nz-page-header-title>
      <nz-page-header-subtitle>-新增/修改 影片分類</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button
          (click)="showAddCategoryModal()"
          nz-button
          nzType="primary"
          nzShape="circle"
        >
          <i nz-icon nzType="plus" nzTheme="outline"></i>
        </button>
      </nz-page-header-extra>
    </nz-page-header>

    <nz-table
      nzShowSizeChanger
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      [nzTotal]="totalCountForCategory"
      [nzPageSize]="pageSizeForCategory"
      [nzPageIndex]="pageIndexForCategory"
      (nzQueryParams)="onCategoryQueryParamsChange($event)"
      [nzData]="listOfCategoryCurrentPageData"
    >
      <thead>
        <tr>
          <th>名稱</th>
          <th>圖片</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of listOfCategoryCurrentPageData">
          <td>{{ data.name }}</td>
          <td *ngIf="data.image">
            <img
              (click)="openImage(data.image, 0)"
              [src]="data.image"
              style="height: 50px"
            />
          </td>
          <td *ngIf="!data.image">無</td>
          <td>
            <a (click)="editCategoryModal(data)" *ngIf="data.recno !== 0">
              編輯
            </a>
            <a *ngIf="data.recno === 0"> 無 </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>

<!-- 瀏覽影片 -->
<nz-modal
  *ngIf="selectVideoData"
  [nzFooter]="null"
  [nzVisible]="isPreviewVideoModalVisible"
  nzTitle="瀏覽影片"
  (nzOnCancel)="closePreviewVideoModal()"
>
  <div nz-row nzJustify="center" class="text-center">
    <video width="400" controls class="text-center;">
      <source [src]="selectVideoData.video" type="video/mp4" />
      Your browser does not support HTML video. 您的瀏覽器不支援影片播放
    </video>
  </div>
</nz-modal>

<!-- 新增/修改影片 -->
<nz-modal
  [nzFooter]="null"
  [nzVisible]="isVideoModalVisible"
  [nzTitle]="selectVideoData ? '修改影片' : '新增影片'"
  (nzOnCancel)="closeVideoModal()"
>
  <nz-row nzAlign="middle" nzJustify="left" [nzGutter]="[16, 16]">
    <!-- <nz-col [nzSm]="6" [nzXs]="24" nzFor="name">
      <span>
        <span style="color: red;">*</span>
        經銷商：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <nz-select [(ngModel)]="addVideoData.dealer" style="width: 100%;">
        <nz-option nzValue="all" nzLabel="all"></nz-option>
        <nz-option
          *ngFor="let option of dealerOptions"
          [nzLabel]="option.name"
          [nzValue]="option.id"
        ></nz-option>
      </nz-select>
    </nz-col> -->

    <nz-col [nzSm]="6" [nzXs]="24" nzFor="name">
      <span>
        <span style="color: red">*</span>
        影片名稱：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <input
        trim
        nz-input
        [(ngModel)]="addVideoData.name"
        placeholder="名稱"
        name="name"
      />
    </nz-col>

    <nz-col [nzSm]="6" [nzXs]="24" nzFor="category">
      <span>
        <span style="color: red">*</span>
        影片分類：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <nz-select
        name="category"
        nzPlaceHolder="選擇分類"
        [(ngModel)]="addVideoData.category"
        style="width: 100%"
      >
        <nz-option
          *ngFor="let item of listOfCategoryOption"
          [nzValue]="item.recno"
          [nzLabel]="item.name"
        ></nz-option>
      </nz-select>
    </nz-col>

    <nz-col [nzSm]="6" [nzXs]="24" nzFor="video">
      <span>
        <span style="color: red">*</span>
        影片檔案：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <nz-upload
        [nzAccept]="['video/mp4', 'video/quicktime', 'video/x-ms-wmv']"
        [(nzFileList)]="fileListVideo"
        [nzBeforeUpload]="beforeUploadVideo"
        [nzLimit]="1"
      >
        <button nz-button><i nz-icon nzType="upload"></i>Select File</button>
      </nz-upload>

      <nz-progress
        *ngIf="uploadVideoPercent"
        [nzPercent]="uploadVideoPercent"
      ></nz-progress>
    </nz-col>

    <nz-col [nzSm]="6" [nzXs]="24" nzFor="image">
      <span>
        <span style="color: red">*</span>
        預覽圖：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <nz-upload
        [nzAccept]="['image/png', 'image/jpeg']"
        [(nzFileList)]="fileListImage"
        [nzBeforeUpload]="beforeUploadImage"
        [nzLimit]="1"
      >
        <button nz-button><i nz-icon nzType="upload"></i>Select File</button>
      </nz-upload>

      <nz-progress
        *ngIf="uploadImagePercent"
        [nzPercent]="uploadImagePercent"
      ></nz-progress>
    </nz-col>

    <nz-col [nzSm]="6" [nzXs]="24" nzFor="wavID">
      <span>
        <span style="color: red">*</span>
        聲波內容：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <nz-select
        name="wavID"
        nzShowSearch
        nzServerSearch
        nzPlaceHolder="搜尋內容"
        [nzShowArrow]="true"
        (nzOnSearch)="searchOptions($event)"
        [(ngModel)]="addVideoData.wavID"
        style="width: 100%"
      >
        <nz-option
          *ngFor="let item of listOfOption"
          [nzValue]="item.value"
          [nzLabel]="item.label"
        ></nz-option>
      </nz-select>
    </nz-col>

    <nz-col [nzSm]="6" [nzXs]="24">
      <span> 關鍵字： </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <span *ngFor="let tag of addVideoData.keywords; let index = index">
        {{ sliceTagName(tag) }}

        <a>
          <i
            nz-icon
            (click)="deleteKeywords(index)"
            nzType="delete"
            nzTheme="outline"
            style="color: red"
          ></i>
        </a>

        <span *ngIf="index !== addVideoData.keywords.length - 1">，</span>
      </span>

      <button
        *ngIf="!inputVisible"
        (click)="showInput()"
        nz-button
        nzType="primary"
        nzShape="circle"
        nzSize="small"
        style="left: 10px"
      >
        <i nz-icon nzType="plus"></i>
      </button>

      <input
        *ngIf="inputVisible"
        nz-input
        #inputElement
        nzSize="small"
        placeholder="關鍵字"
        type="text"
        [(ngModel)]="inputValue"
        (keypress)="handleInputConfirm($event)"
        (blur)="handleInputConfirm($event)"
      />
    </nz-col>

    <nz-col [nzSpan]="24" style="text-align: center">
      <button
        *ngIf="selectVideoData"
        nz-button
        (click)="putVideoForm()"
        nzType="primary"
      >
        修改
      </button>
      <button
        *ngIf="!selectVideoData"
        nz-button
        (click)="postVideoForm()"
        nzType="primary"
      >
        新增
      </button>
    </nz-col>
  </nz-row>
</nz-modal>

<!-- 新增/修改影片分類 -->
<nz-modal
  [nzFooter]="null"
  [nzVisible]="isCategoryModalVisible"
  [nzTitle]="selectVideoData ? '修改分類' : '新增分類'"
  (nzOnCancel)="closeCategoryModal()"
>
  <nz-row nzAlign="middle" nzJustify="left" [nzGutter]="[16, 16]">
    <nz-col [nzSm]="6" [nzXs]="24" nzFor="name">
      <span>
        <span style="color: red">*</span>
        分類名稱：
      </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <input
        trim
        nz-input
        [(ngModel)]="addCategoryData.name"
        placeholder="名稱"
        name="name"
      />
    </nz-col>

    <nz-col [nzSm]="6" [nzXs]="24" nzFor="image">
      <span> 圖片： </span>
    </nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <nz-upload
        [nzAccept]="['image/png', 'image/jpeg']"
        [(nzFileList)]="fileListImage"
        [nzBeforeUpload]="beforeUploadImage"
        [nzLimit]="1"
      >
        <button nz-button><i nz-icon nzType="upload"></i>Select File</button>
      </nz-upload>

      <nz-progress
        *ngIf="uploadImagePercent"
        [nzPercent]="uploadImagePercent"
      ></nz-progress>
    </nz-col>

    <nz-col [nzSpan]="24" style="text-align: center">
      <button
        *ngIf="selectCategoryData"
        nz-button
        (click)="putCategoryForm()"
        nzType="primary"
      >
        修改
      </button>
      <button
        *ngIf="!selectCategoryData"
        nz-button
        (click)="postCategoryForm()"
        nzType="primary"
      >
        新增
      </button>
    </nz-col>
  </nz-row>
</nz-modal>
