import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from '../../app.service';

interface HostAccount {
  email: string;
  pass: string;
  name: string;
}

@Component({
  selector: 'app-host-account',
  templateUrl: './host-account.component.html',
  styleUrls: ['./host-account.component.scss'],
})
export class HostAccountComponent implements OnInit {
  HostAccount: HostAccount = {
    email: '',
    pass: '',
    name: '',
  };
  passwordVisible = false;

  constructor(private http: HttpClient, public appService: AppService) {}

  ngOnInit(): void {}

  addHostAccount(): void {
    if (
      this.HostAccount.name === '' ||
      this.HostAccount.pass === '' ||
      this.HostAccount.email === ''
    ) {
      this.appService.showMessage('warning', '必填欄位不能空白');
      return;
    }
    const emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    // console.log(this.HostAccount.email.match(emailRule));
    if (this.HostAccount.email.match(emailRule) === null) {
      this.appService.showMessage('warning', 'email格式不對');
      return;
    }

    this.http
      .post('api/addHostAccount', this.HostAccount, { responseType: 'text' })
      // .get('api/addHostAccount', { responseType: 'text' })
      .toPromise()
      .then((res) => {
        // console.log(res);
        // Fail 501 資料填入內容錯誤
        // Fail 501 本電子郵件已經被使用了

        // Success
        // 完成，請在30分鐘內收取您的email來開通帳號
        if (res.indexOf('Fail 501') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '創建失敗：' + message);
        } else if (res.indexOf('Fail 502') > -1) {
          const message = res.substr(9);
          this.appService.showMessage('warning', '創建失敗：' + message);
        } else if (res.indexOf('Success') > -1) {
          this.appService.showMessage(
            'success',
            '創建成功，請在30分鐘內收取您的email來開通帳號'
          );
        } else {
          this.appService.showMessage('warning', '創建失敗，請稍後再試一次');
        }
      })
      .catch((err) => {
        // console.log(err);
        this.appService.showMessage('warning', '創建失敗，請稍後再試一次');
      });
  }
}
