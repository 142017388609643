<div nz-row [nzGutter]="[0, 24]" nzJustify="center" class="text-center">
  <div nz-col nzSpan="24">
    <h4 nz-typography>透過設定，可以即時操控本主機的廣告呈現內容</h4>
  </div>

  <div nz-col nzSpan="24">
    <form nz-form>
      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="user"
          >經銷商</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-select
            [(ngModel)]="marquee.multipleDealer"
            (ngModelChange)="onChangeDealer($event)"
            nzMode="multiple"
            nzPlaceHolder="選擇經銷商"
            name="dealer"
          >
            <nz-option nzValue="all" nzLabel="all"></nz-option>
            <nz-option
              *ngFor="let option of appService.$getDealerList()"
              [nzLabel]="option.name"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="text"
          >跑馬燈內容
          <button
            (click)="showAddModal()"
            nz-button
            nzType="primary"
            nzShape="circle"
            nzSize="small"
          >
            <i nz-icon nzType="plus" nzTheme="outline"></i>
          </button>
        </nz-form-label>

        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-select
            name="text"
            nzShowSearch
            nzServerSearch
            nzPlaceHolder="搜尋內容"
            [nzShowArrow]="false"
            (nzOnSearch)="searchOptions($event)"
            [(ngModel)]="marquee.setBarText"
          >
            <nz-option
              *ngFor="let option of listOfOption"
              [nzLabel]="option.label"
              [nzValue]="option.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="position"
          >跑馬燈位置</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-radio-group [(ngModel)]="marquee.setBarPos" name="position">
            <label nz-radio nzValue="1">螢幕上方</label>
            <label nz-radio nzValue="2">螢幕下方</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="size"
          >尺寸</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nz-radio-group [(ngModel)]="marquee.setBarSize" name="size">
            <label nz-radio nzValue="50">小</label>
            <label nz-radio nzValue="70">中</label>
            <label nz-radio nzValue="90">大</label>
            <label nz-radio nzValue="120">特大</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="color"
          >內文顏色</nz-form-label
        >
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <input
            [(ngModel)]="marquee.setTextColor"
            name="color"
            type="color"
            id="favcolor"
            name="favcolor"
            value="#ff0000"
          />
        </nz-form-control>
      </nz-form-item>
    </form>

    <div nz-col nzSpan="24">
      <button nz-button nzType="primary" (click)="post()">確定</button>
    </div>
  </div>
</div>

<!-- 跑馬燈內容 -->
<nz-modal
  [nzFooter]="null"
  [nzVisible]="isMarqueeModalVisible"
  nzTitle="新增跑馬燈內容"
  (nzOnCancel)="closeAddModal()"
>
  <form nz-form>
    <nz-form-item>
      <nz-form-label nzRequired [nzSm]="6" [nzXs]="24" nzFor="content"
        >跑馬燈內容</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <input
          nz-input
          [(ngModel)]="addMarqueeData.content"
          trim
          name="content"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control
        [nzXs]="{ span: 24, offset: 0 }"
        [nzSm]="{ span: 16, offset: 6 }"
      >
        <button nz-button (click)="submitForm()" nzType="primary">
          新增
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>
