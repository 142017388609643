import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { cloneDeep as _cloneDeep } from 'lodash';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

interface MarqueeData {
  recno?: number;
  content: string;
}

@Component({
  selector: 'app-setting-marquee',
  templateUrl: './setting-marquee.component.html',
  styleUrls: ['./setting-marquee.component.scss'],
})
export class SettingMarqueeComponent implements OnInit {
  isMarqueeModalVisible = false;
  addMarqueeData: MarqueeData = { content: '' };
  selectMarqueeData: MarqueeData;

  loading = false;
  listOfCurrentPageData: MarqueeData[] = [];
  totalCount: number;
  pageSize = 10;
  pageIndex = 1;

  constructor(
    private http: HttpClient,
    private msg: NzMessageService,
    private modal: NzModalService
  ) {}

  ngOnInit(): void {
    this.loadDataFromServer(this.pageIndex, this.pageSize);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params);
    const { pageSize, pageIndex } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.loadDataFromServer(pageIndex, pageSize);
  }

  loadDataFromServer(pageIndex: number, pageSize: number): void {
    this.loading = true;

    const params = new HttpParams()
      .append('pageIndex', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    this.http
      .get<any>('/api/marquee', { params })
      .toPromise()
      .then((res) => {
        // console.log(res);
        this.loading = false;
        this.listOfCurrentPageData = res.data;
        this.totalCount = res.total;
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  editAudioModal(data): void {
    this.selectMarqueeData = _cloneDeep(data);
    this.addMarqueeData = _cloneDeep(data);
    this.isMarqueeModalVisible = true;
  }
  deleteAudioModal(data): void {
    this.modal.confirm({
      nzTitle: '確定要刪除嗎?',
      nzContent: data.name,
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.http
          .delete<any>('/api/marquee', { params: { recno: data.recno } })
          .toPromise()
          .then((res) => {
            this.msg.create('success', '刪除成功');
            this.loadDataFromServer(this.pageIndex, this.pageSize);
          })
          .catch((err) => {
            console.log(err.message);
            this.msg.create('warning', '刪除失敗');
          });
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel'),
    });
  }
  showAddModal(): void {
    this.addMarqueeData = {
      content: '',
    };
    this.isMarqueeModalVisible = true;
    this.selectMarqueeData = null;
  }
  closeAddModal(): void {
    this.isMarqueeModalVisible = false;
  }

  async submitForm() {
    if (!this.addMarqueeData.content) {
      this.msg.warning('內容不可空白');
      return false;
    }

    await this.http
      .post<any>('/api/marquee', this.addMarqueeData)
      .toPromise()
      .then((res) => {
        this.msg.create('success', '新增成功');
        this.closeAddModal();
        this.loadDataFromServer(this.pageIndex, this.pageSize);
      })
      .catch((error) => {
        console.log(error.message);
        this.msg.create('waring', '新增失敗');
      });
  }

  async putForm() {
    if (!this.addMarqueeData.content) {
      this.msg.warning('內容不可空白');
      return false;
    }

    await this.http
      .put<any>('/api/marquee', this.addMarqueeData)
      .toPromise()
      .then((res) => {
        this.msg.create('success', '修改成功');
        this.closeAddModal();
        this.loadDataFromServer(this.pageIndex, this.pageSize);
      })
      .catch((error) => {
        console.log(error.message);
        this.msg.create('waring', '修改失敗');
      });
  }
}
