import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../app.service';

interface Video {
  multipleDealer;
  setVideo;
  setVideoPos;
  setVideoSize;
}

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  video: Video = {
    multipleDealer: ['all'],
    setVideo: '',
    setVideoPos: '1',
    setVideoSize: '1280x720',
  };
  listOfOption: Array<{ label: string; value: string }> = [];
  dealerOptions = [];

  constructor(private http: HttpClient, public appService: AppService) {}

  ngOnInit(): void {
    this.video.multipleDealer = this.appService.multipleDealer;
  }

  searchOptions(value: string): void {
    this.http
      .jsonp<{ result: Array<{ video: string; name: string }> }>(
        `/api/jsonp?key=video&searchText=${value}`,
        'callback'
      )
      .subscribe((data) => {
        const listOfOption: Array<{ value: string; label: string }> = [];
        data.result.forEach((item) => {
          listOfOption.push({
            value: item.video,
            label: item.name,
          });
        });
        this.listOfOption = listOfOption;
      });
  }

  onChangeDealer(val) {
    const lastValue = val[val.length - 1];
    if (lastValue === 'all') {
      this.video.multipleDealer = ['all'];
    } else {
      const index = val.indexOf('all');
      if (index !== -1) {
        val.splice(index, 1);
      }
      this.video.multipleDealer = [...val];
    }

    this.appService.setMultipleDealer(this.video.multipleDealer);
  }
  post(): void {
    if (!this.video.setVideo) {
      this.appService.showMessage('warning', '必填欄位不能空白');
      return;
    }

    this.appService.onLoading(true);

    this.http
      .get<any>('/api/sendOP', {
        params: { ...this.video },
      })
      .toPromise()
      .then((res) => {})
      .catch((err) => {});

    const historyLog = {
      type: 'video',
      multipleDealer: this.video.multipleDealer,
      content: this.video.setVideo,
    };
    this.http
      .post<any>('/api/historyLog', historyLog)
      .toPromise()
      .then((res) => {})
      .catch((err) => {});

    setTimeout(() => {
      this.appService.showMessage('warning', '已送出指令');
      this.appService.onLoading(false);
    }, 1000);
  }
}
