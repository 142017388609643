<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    [nzCollapsedWidth]="0"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo">
      <a routerLink="/">
        <img src="../assets/image/logo.png" alt="logo" />
        <h1>管理後台</h1>
      </a>
    </div>
    <ul
      nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li
        *ngIf="getAuth() === '1' || getAuth() === '2'"
        nz-submenu
        nzOpen
        nzTitle="設定"
        nzIcon="setting"
      >
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/UploadLogo">主機logo</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/UploadVideo">影片</a>
          </li>
          <!-- <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/UploadSoundWave">聲波</a>
          </li> -->
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/UploadSoundWave2">聲波-v2</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/SettingMarquee">跑馬燈內容</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/SettingQrcode">廣告二維碼</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/UserHost">客戶主機顯示</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/HostAccount">創建主機帳號</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/RemoveHost">解除主機綁定</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/SearchHost">查詢帳號</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="功能" nzIcon="send">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/AdAction">廣告開關</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/Marquee">跑馬燈內容設定</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/QRcode">廣告二維碼設定</a>
          </li>
          <!-- <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/SoundWave">低頻聲波</a>
          </li> -->
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/SoundWave2">低頻聲波-v2</a>
          </li>
          <!-- <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/Video">首頁視頻</a>
          </li> -->
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/VideoWav">首頁視頻</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/AdSetting">廣告設定</a>
          </li>
          <!-- <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/VideoWav">視頻聲波</a>
          </li> -->
        </ul>
      </li>
      <li
        *ngIf="getAuth() !== '0'"
        nz-submenu
        nzOpen
        nzTitle="帳號"
        nzIcon="user"
      >
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/UserAccount">修改密碼</a>
          </li>
        </ul>
      </li>

      <li
        *ngIf="getAuth() === '1'"
        nz-submenu
        nzOpen
        nzTitle="管理員"
        nzIcon="exclamation-circle"
      >
        <ul>
          <!-- <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/Test">測試</a>
          </li> -->
          <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/AccountSetting">帳戶管理</a>
          </li>
          <!-- <li nz-menu-item nzMatchRouter>
            <a (click)="close_nav()" routerLink="/VideoWav">視頻聲波</a>
          </li> -->
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="sidenav_click()">
          <i
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          ></i>
        </span>

        <span class="header-trigger" style="float: right; display: inline-flex">
          <button
            *ngIf="getAuth() == '1' || getAuth() == '2'"
            nz-button
            nzType="default"
            nzShape="round"
            (click)="showUserAddModal()"
          >
            <i nz-icon nzType="user-add" nzTheme="outline"></i>新增
          </button>

          <button
            *ngIf="getAuth() === '0'"
            nz-button
            nzType="primary"
            nzShape="round"
            (click)="showModal()"
          >
            <i nz-icon nzType="login"></i>登入
          </button>

          <button
            *ngIf="getAuth() !== '0'"
            nz-button
            nzDanger
            nzType="default"
            nzShape="round"
            (click)="logOut()"
          >
            <i nz-icon nzType="logout"></i>登出
          </button>
        </span>
      </div>
    </nz-header>
    <nz-content (click)="close_nav()">
      <nz-spin nzTip="Loading..." [nzSpinning]="getIsLoading()">
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-spin>
    </nz-content>
  </nz-layout>
</nz-layout>
