<form
  nz-form
  [formGroup]="validateForm"
  class="login-form"
  (ngSubmit)="submitForm()"
>
  <nz-form-item>
    <nz-form-label nzFor="account" nzRequired nzNoColon></nz-form-label>
    <nz-form-control nzErrorTip="請輸入帳號!">
      <nz-input-group nzPrefixIcon="user">
        <input
          type="text"
          nz-input
          placeholder="帳號"
          formControlName="account"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="password" nzRequired nzNoColon></nz-form-label>
    <nz-form-control nzErrorTip="請輸入密碼!">
      <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
        <input
          [type]="passwordVisible ? 'text' : 'password'"
          nz-input
          placeholder="密碼"
          formControlName="password"
        />
      </nz-input-group>
      <ng-template #suffixTemplate>
        <i
          nz-icon
          [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
          (click)="passwordVisible = !passwordVisible"
        ></i>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <button
    nz-button
    class="login-form-button login-form-margin"
    [nzType]="'primary'"
  >
    登 入
  </button>
</form>
