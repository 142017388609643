import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Lightbox } from 'ngx-lightbox';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

interface Data {
  recno?: number;
  user_id: string;
  name: string;
  image: string;
}

@Component({
  selector: 'app-upload-logo',
  templateUrl: './upload-logo.component.html',
  styleUrls: ['./upload-logo.component.scss'],
})
export class UploadLogoComponent implements OnInit {
  isVisible = false;

  avatarUrl?: string;
  newImage;
  file;
  formData: FormData;

  loading = false;
  listOfCurrentPageData: Data[] = [];
  selectedData: Data;
  totalCount: number;
  pageSize = 10;
  pageIndex = 1;

  constructor(
    private msg: NzMessageService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private _lightbox: Lightbox
  ) {}

  ngOnInit(): void {
    this.loadDataFromServer(1, 10);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params);
    const { pageSize, pageIndex } = params;
    this.loadDataFromServer(pageIndex, pageSize);
  }

  loadDataFromServer(pageIndex: number, pageSize: number): void {
    this.loading = true;

    const params = new HttpParams()
      .append('pageIndex', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    this.http
      .get<any>('/api/user', { params })
      .toPromise()
      .then((res) => {
        // console.log(res);
        this.loading = false;
        this.listOfCurrentPageData = res.data;
        this.totalCount = res.total;
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  showEditModal(data): void {
    // console.log(data);
    this.formData = new FormData();
    this.file = null;
    this.newImage = null;
    this.selectedData = data;
    this.isVisible = true;
  }

  closeEditModal(): void {
    this.isVisible = false;
    this.selectedData = null;
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    // console.log(file);
    this.file = file;
    if (file.size > 2 * 1024 * 1000) {
      this.msg.warning('圖片太大，不得超過2MB');
      return false;
    }
    const objectURL = URL.createObjectURL(file);
    this.newImage = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    return false;
  };

  async modify() {
    this.formData.append('image', this.file);

    let isUploadImageSuccess = false;
    this.http.post<any>('/api/upload/image', this.formData).subscribe((res) => {
      const image = res.url;
      // console.log(res);
      this.msg.create('success', '圖片上傳成功');

      this.http
        .put<any>('api/user', {
          user_id: this.selectedData.user_id,
          image,
        })
        .toPromise()
        .then((res) => {
          this.selectedData.image = image;
          this.msg.success('修改成功');
          this.isVisible = false;
        })
        .catch((err) => {
          this.msg.warning('修改失敗');
        });
    });
  }

  openImage(src, index): void {
    // console.log(src + index);
    const album = [
      {
        src,
        caption: src,
        thumb: src,
      },
    ];
    this._lightbox.open(album, 0);
  }
}
