import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpParams,
  HttpEventType,
} from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { cloneDeep as _cloneDeep } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Lightbox } from 'ngx-lightbox';
import { AppService } from '../../app.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import sha1 from 'crypto-js/sha1';

interface Advertisement {
  account: string;
  auth: string;
}

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.scss'],
})
export class AccountSettingComponent implements OnInit {
  isAdvertisementModalVisible = false;
  isAdvertisementModalVisible2 = false;
  passwordVisible = false;
  newPassword: '';
  uploading = false;
  uploadImageSuccess = false;
  uploadImagePercent;
  fileListImage: NzUploadFile[] = [];

  addAdvertisementData: Advertisement = {
    account: '',
    auth: '',
  };
  selectAdvertisementData: Advertisement;

  imageFile: FormData = new FormData();

  loading = false;
  listOfAdvertisementCurrentPageData: Advertisement[] = [];
  totalCount: number;
  pageSize = 10;
  pageIndex = 1;

  constructor(
    private http: HttpClient,
    private msg: NzMessageService,
    public changeDetectorRef: ChangeDetectorRef,
    private modal: NzModalService,
    private _lightbox: Lightbox,
    public appService: AppService // tslint:disable-next-line: variable-name
  ) {}

  ngOnInit(): void {}

  onAdvertisementQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex } = params;
    this.pageSize = pageIndex;
    this.pageSize = pageSize;
    this.loadAdvertisementDataFromServer(pageIndex, pageSize);
  }

  loadAdvertisementDataFromServer(pageIndex: number, pageSize: number): void {
    this.loading = true;

    const params = new HttpParams()
      .append('pageIndex', `${pageIndex}`)
      .append('pageSize', `${pageSize}`);

    this.http
      .get<any>('/api/account', { params })
      .toPromise()
      .then((res) => {
        // console.log(res);
        this.loading = false;
        this.listOfAdvertisementCurrentPageData = res.data;
        this.totalCount = res.total;
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  modifyAuthModal(data): void {
    this.selectAdvertisementData = _cloneDeep(data);
    this.addAdvertisementData = _cloneDeep(data);
    this.isAdvertisementModalVisible = true;
  }

  modifyPasswordModal(data): void {
    this.selectAdvertisementData = _cloneDeep(data);
    this.addAdvertisementData = _cloneDeep(data);
    this.isAdvertisementModalVisible2 = true;
  }

  closeModifyPasswordModal(): void {
    this.isAdvertisementModalVisible2 = false;
    this.selectAdvertisementData = null;
  }

  closeModifyAuthModal(): void {
    this.isAdvertisementModalVisible = false;
    this.selectAdvertisementData = null;
    this.uploadImagePercent = null;
    this.fileListImage = [];
  }

  putAccountPassword() {
    const obj = {
      account: this.addAdvertisementData.account,
      newPassowrd: sha1(this.newPassword).toString(),
    };
    this.http
      .put<any>('/api/account', obj)
      .toPromise()
      .then((res) => {
        this.msg.create('success', '修改成功');
        this.loadAdvertisementDataFromServer(this.pageIndex, this.pageSize);
      })
      .catch((error) => {
        // console.log(error.message);
        this.msg.create('waring', '修改失敗');
      });

    this.closeModifyPasswordModal();
  }

  putAccountAuth() {
    const obj = {
      account: this.addAdvertisementData.account,
      auth: this.addAdvertisementData.auth,
    };
    this.http
      .put<any>('/api/account', obj)
      .toPromise()
      .then((res) => {
        this.msg.create('success', '修改成功');
        this.loadAdvertisementDataFromServer(this.pageIndex, this.pageSize);
      })
      .catch((error) => {
        // console.log(error.message);
        this.msg.create('waring', '修改失敗');
      });

    this.closeModifyAuthModal();
  }
}
