import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-host',
  templateUrl: './user-host.component.html',
  styleUrls: ['./user-host.component.scss']
})
export class UserHostComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
